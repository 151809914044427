import React, { useState } from 'react';
import Logo from '../images/logo.webp';
import { logoutService } from '../services/LogoutService';



function CompleteSign() {

    const handleLogoutClick = () => setShowLogoutModal(true);
    const [showLogoutModal, setShowLogoutModal] = useState(false);


    const confirmLogout = () => {
        setShowLogoutModal(false);
        logoutService('Session');
    };



    return (

        <div className="bg-gray-100 min-h-screen flex flex-col">

            <header className="w-full bg-white shadow-md flex items-center justify-between p-3">
                <div className="flex items-center">
                    <img src={Logo} alt="Logo" className="h-12" />
                </div>
                <div className="flex items-center">
                    <button
                        className="bg-green-600 text-white py-2 px-10 rounded cursor-pointer"
                        onClick={handleLogoutClick}
                    >
                        LogOut
                    </button>
                </div>
            </header>


            <p className='text-center mt-48 text-2xl'>

                You have successfully complete the onboarding process. <br />

                <p className='mt-3 text-green-500'>Welcome to The Genie Family!</p>   <br />

                You will receive your copy of the merchant agreement over email. <br />

                Someone from the team will get in-touch with your shortly to help you go live on our platform. <br />
            </p>


            {showLogoutModal && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                    <div className="bg-white rounded-lg shadow-lg p-5 w-full max-w-md">
                        <h2 className="text-xl font-semibold mb-3 text-center">Are you sure you want to log out?</h2>
                        <div className="flex justify-center space-x-4 mt-5">
                            <button
                                className="bg-red-500 text-white py-2 px-6 rounded-lg"
                                onClick={confirmLogout}
                            >
                                Yes, Log Out
                            </button>
                            <button
                                className="bg-gray-300 text-black py-2 px-6 rounded-lg"
                                onClick={() => setShowLogoutModal(false)}
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>


    )
}

export default CompleteSign
