// services/api.js
import axios from "axios";
import { refreshTokenAPI } from '../services/AuthApi';
import { logoutService } from "./LogoutService";

const BASE_URL = `${process.env.REACT_APP_API_ENDPOINT}/${localStorage.getItem('endpoint')}`;

const ApiService = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  },
});

// Helper function to parse the expiry time string and return a Date object
const parseExpiryTime = (expiryString) => {
  return new Date(expiryString.replace(" ", "T"));
};

ApiService.interceptors.request.use(
  async (config) => {
    // Get access token, refresh token, and token expiry times from session storage
    const accessToken = localStorage.getItem("accessToken");
    const refreshToken = localStorage.getItem("refreshToken");
    const accessTokenExpiry = localStorage.getItem("accessTokenExpiry");
    const refreshTokenExpiry = localStorage.getItem("refreshTokenExpiry");

    // Parse expiry strings into Date objects
    const accessTokenExpiryDate = accessTokenExpiry ? parseExpiryTime(accessTokenExpiry) : null;
    const refreshTokenExpiryDate = refreshTokenExpiry ? parseExpiryTime(refreshTokenExpiry) : null;

    // Get current date and time
    const now = new Date();

    // Check if access token is expired or will expire within 1 minute
    const accessTokenExpiresIn = accessTokenExpiryDate - now;
    const refreshTokenExpiresIn = refreshTokenExpiryDate - now;

    if (accessTokenExpiresIn < 60000) {
      // If access token is expired or about to expire
      if (refreshToken && refreshTokenExpiresIn > 0) {
        // Check if the refresh token is valid and not expired
        try {
          // Call the refresh token API to get a new access token
          const response = await refreshTokenAPI(refreshToken);

          // Update session storage with the new tokens and expiry times
          localStorage.setItem("accessToken", response.accessToken);
          localStorage.setItem("refreshToken", response.refreshToken);
          localStorage.setItem("accessTokenExpiry", response.accessTokenExpiry);
          localStorage.setItem("refreshTokenExpiry", response.refreshTokenExpiry);

          // Set the new access token in the request headers
          config.headers.Authorization = `Bearer ${response.accessToken}`;
        } catch (error) {
          console.error("Error refreshing access token:", error);
          logoutService('Session');
          // If refresh token is invalid or API call fails, log out

          throw error;
        }
      } else {
        // If refresh token is invalid or expired, log out the user
        console.warn("Refresh token expired. Logging out.");
        logoutService('Session');
        throw new Error("Session expired. Please log in again.");
      }
    } else {
      // If access token is still valid, set it in the request headers
      if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`;
      }
    }

    return config;
  },
  (error) => {
    // Handle request errors
    return Promise.reject(error);
  }
);

export const logout = async (logoutType) => {
  try {
    // Manually specify the base URL for logout
    const customBaseUrl = `${process.env.REACT_APP_API_ENDPOINT}/api`;
    
  
    const response = await axios.post(`${customBaseUrl}/logout`, { logoutType });
    // const response = await ApiService.post("/logout", { logoutType });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchActiveSearchCategories = async () => {
  try {
    const token = localStorage.getItem("accessToken");
    console.log(token, "Token Found");

    if (!token) {
      throw new Error("No access token found");
    } else {
      console.log("token found");
    }

    const response = await ApiService.get(
      "/restaurants/active_search_categories"
    );
    console.log(response, "response");

    return response.data.search_categories;
  } catch (error) {
    console.error("Error fetching search categories:", error);
    throw error;
  }
};

export const uploadPanImage = async (fileType) => {
  try {
    const response = await ApiService.get(`/restaurants/register/pan_upload`);
    return response.data;
  } catch (error) {
    console.error("Error uploading PAN image:", error);
    throw error;
  }
};

export const confirmPanUpload = async (metadataId, isUploadSuccess) => {
  try {
    const response = await ApiService.post(
      `/restaurants/register/pan_upload/upload_confirmation`,
      { metadataId, isUploadSuccess },
    );
    return response.data;
  } catch (error) {
    console.error("Error confirming PAN upload:", error);
    throw error;
  }
};

export const fetchPanUrl = async () => {
  try {
    const response = await ApiService.get(
      `/restaurants/register/pan_url/fetch`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deletePanUrl = async (url) => {
  try {
    const response = await ApiService.post(
      `/restaurants/register/pan/delete`,
      { url },
      
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const uploadGstImage = async (fileType) => {
  try {
    const response = await ApiService.get(
      `/restaurants/register/gst_upload`,
      {
        params: { fileType }
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error uploading GST image:", error);
    throw error;
  }
};

export const confirmGstUpload = async (metadataId, isUploadSuccess) => {
  try {
    const response = await ApiService.post(
      `/restaurants/register/gst_upload/upload_confirmation`,
      { metadataId, isUploadSuccess }
    );
    return response.data;
  } catch (error) {
    console.error("Error confirming GST upload:", error);
    throw error;
  }
};

export const fetchGstUrl = async () => {
  try {
    const response = await ApiService.get(
      `/restaurants/register/gst_url/fetch`,
      
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteGstUrl = async (url) => {
  try {
    const response = await ApiService.post(
      `/restaurants/register/gst/delete`,
      { url },
      
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const uploadFssaiImage = async (fileType) => {
  try {
    const response = await ApiService.get(
      `/restaurants/register/fssai_upload`,
      {
        params: { fileType }
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const confirmFssaiUpload = async (metadataId, isUploadSuccess) => {
  try {
    const response = await ApiService.post(
      `/restaurants/register/fssai_upload/upload_confirmation`,
      {
        metadataId,
        isUploadSuccess,
      },
      
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchFssaiUrl = async () => {
  try {
    const response = await ApiService.get(
      `/restaurants/register/fssai_url/fetch`,
      
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteFssaiUrl = async (url) => {
  try {
    const response = await ApiService.post(
      `/restaurants/register/fssai/delete`,
      { url },
      
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
  

const restaurant_Id = localStorage.getItem("restaurant_Id");
console.log("while Fetching", restaurant_Id);

  // Generate Store Image Upload Presigned URL
  export const uploadStoreImage = async (fileType, restaurantId) => {
    try {
        console.log("Token in Stor Images",localStorage.getItem("accessToken"));
        
      const response = await ApiService.get("/restaurants/store_image/upload", {
        params: { fileType, restaurantId },
      });
      return response.data;
    } catch (error) {
      console.error("Error generating store image upload URL:", error);
      throw error;
    }
  };
  
  // Confirm Store Image Upload
  export const confirmStoreImageUpload = async (metadataId, isUploadSuccess, restaurantId) => {
    try {
      const response = await ApiService.post(
        "/restaurants/store_image/confirm",
        { metadataId, isUploadSuccess, restaurantId });
      return response.data;
    } catch (error) {
      console.error("Error confirming store image upload:", error);
      throw error;
    }
  };
  
  // Generate Profile Image Upload Presigned URL
  export const uploadProfileImage = async (fileType, restaurantId) => {
    try {
      const response = await ApiService.get("/restaurants/profile_image/upload", {
        params: { fileType, restaurantId },
      });
      return response.data;
    } catch (error) {
      console.error("Error generating profile image upload URL:", error);
      throw error;
    }
  };
  
  // Confirm Profile Image Upload
  export const confirmProfileImageUpload = async (metadataId, isUploadSuccess, restaurantId) => {
    try {
      const response = await ApiService.post(
        "/restaurants/profile_image/confirm",
        { metadataId, isUploadSuccess, restaurantId }
      );
      return response.data;
    } catch (error) {
      console.error("Error confirming profile image upload:", error);
      throw error;
    }
  };
  
  // Generate Food Image Upload Presigned URL
  export const uploadFoodImage = async (fileType, restaurantId) => {
    try {
      const response = await ApiService.get("/restaurants/food_image/upload", {
        params: { fileType, restaurantId },
      });
      return response.data;
    } catch (error) {
      console.error("Error generating food image upload URL:", error);
      throw error;
    }
  };
  
  // Confirm Food Image Upload
  export const confirmFoodImageUpload = async (metadataId, isUploadSuccess, restaurantId) => {
    try {
      const response = await ApiService.post(
        "/restaurants/food_image/confirm",
        { metadataId, isUploadSuccess, restaurantId });
      return response.data;
    } catch (error) {
      console.error("Error confirming food image upload:", error);
      throw error;
    }
  };
  
  // Generate Delivery Menu Image Upload Presigned URL
  export const uploadDeliveryMenuImage = async (fileType, restaurantId) => {
    try {
      const response = await ApiService.get("/restaurants/delivery_menu_image/upload", {
        params: { fileType, restaurantId },
      });
      return response.data;
    } catch (error) {
      console.error("Error generating delivery menu image upload URL:", error);
      throw error;
    }
  };
  
  // Confirm Delivery Menu Image Upload
  export const confirmDeliveryMenuImageUpload = async (metadataId, isUploadSuccess, restaurantId) => {
    try {
      const response = await ApiService.post(
        "/restaurants/delivery_menu_image/confirm",
        { metadataId, isUploadSuccess, restaurantId });
      return response.data;
    } catch (error) {
      console.error("Error confirming delivery menu image upload:", error);
      throw error;
    }
  };

  // Fetch Store Image URLs
export const fetchStoreImages = async (restaurantId) => {
  try {
    const response = await ApiService.get("/restaurants/store_image/fetch", {
      // params: { restaurantId },
      params: { restaurantId:restaurant_Id }
    });
    return response.data.store_image_urls;
  } catch (error) {
    console.error("Error fetching store images:", error);
    throw error;
  }
};

// Delete Store Image
export const deleteStoreImage = async (url, restaurantId) => {
  try {
    const response = await ApiService.post(
      "/restaurants/store_image/delete",
      { url, restaurantId },
      
    );
    return response.data;
  } catch (error) {
    console.error("Error deleting store image:", error);
    throw error;
  }
};

// Fetch Profile Image URL
export const fetchProfileImage = async (restaurantId) => {
  try {
    const response = await ApiService.get("/restaurants/profile_image/fetch", {
      // params: { restaurantId },
      params: { restaurantId:restaurant_Id }
    });
    return response.data.profile_image_url;
  } catch (error) {
    console.error("Error fetching profile image:", error);
    throw error;
  }
};

// Delete Profile Image
export const deleteProfileImage = async (url, restaurantId) => {
  try {
    const response = await ApiService.post(
      "/restaurants/profile_image/delete",
      { url, restaurantId },
      
    );
    return response.data;
  } catch (error) {
    console.error("Error deleting profile image:", error);
    throw error;
  }
};

// Fetch Food Image URLs
export const fetchFoodImages = async (restaurantId) => {
  try {
    const response = await ApiService.get("/restaurants/food_image/fetch", {
      // params: { restaurantId },
      params: { restaurantId:restaurant_Id }
    });
    return response.data.food_image_urls;
  } catch (error) {
    console.error("Error fetching food images:", error);
    throw error;
  }
};

// Delete Food Image
export const deleteFoodImage = async (url, restaurantId) => {
  try {
    const response = await ApiService.post(
      "/restaurants/food_image/delete",
      { url, restaurantId },
      
    );
    return response.data;
  } catch (error) {
    console.error("Error deleting food image:", error);
    throw error;
  }
};

// Fetch Delivery Menu Image URLs
export const fetchDeliveryMenuImages = async (restaurantId) => {
  try {
    const response = await ApiService.get("/restaurants/delivery_menu_image/fetch", {
      // params: { restaurantId },
      params: { restaurantId:restaurant_Id }
    });
    return response.data.delivery_menu_image_urls;
  } catch (error) {
    console.error("Error fetching delivery menu images:", error);
    throw error;
  }
};

// Delete Delivery Menu Image
export const deleteDeliveryMenuImage = async (url, restaurantId) => {
  try {
    const response = await ApiService.post(
      "/restaurants/delivery_menu_image/delete",
      { url, restaurantId },
      
    );
    return response.data;
  } catch (error) {
    console.error("Error deleting delivery menu image:", error);
    throw error;
  }
};


  // Restaurant Information API
  export const restaurantInfo = async (requestType, data = {}) => {
    try {
      const response = await ApiService.post(
        "/restaurants/restaurant_onboarding",
        {
          request_type: requestType,
          request_page: "res_info",
          data,
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error in restaurantInfo:", error.response?.data || error.message);
      throw error;
    }
  };

  // Menu Operations API
export const menuOperationsDetails = async (requestType, data = {}) => {
  try {
    const response = await ApiService.post(
      "/restaurants/restaurant_onboarding",
      {
        request_type: requestType,
        request_page: "menu_ops_details",
        data,
      },
      
    );
    return response.data;
  } catch (error) {
    console.error("Error in menuOperationsDetails:", error.response?.data || error.message);
    throw error;
  }
};

// Restaurant Documents API
export const restaurantDocuments = async (requestType, data = {}) => {
  try {
    const response = await ApiService.post(
      "/restaurants/restaurant_onboarding",
      {
        request_type: requestType,
        request_page: "res_documents",
        data,
      },
      
    );
    return response.data;
  } catch (error) {
    console.error("Error in restaurantDocuments:", error.response?.data || error.message);
    throw error;
  }
};

