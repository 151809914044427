
import React, { useState, useEffect } from 'react';
import Logo from '../images/logo.webp';
import './Registration.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAlt, faPlay, faCheckCircle, faTimes} from '@fortawesome/free-solid-svg-icons';
import Verified from '../images/verified.webp';
import { Link, useNavigate } from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';
import { logoutService } from '../services/LogoutService';

// Custom Input Component to restrict manual input
// const CustomInput = forwardRef(({ value, onClick }, ref) => (
//     <div className="relative w-full">
//         <input
//             className="block w-full border border-gray-300 outline-none rounded-md pl-3 pr-8 py-1"
//             onClick={onClick}
//             value={value}
//             ref={ref}
//             readOnly
//         />
//         <FontAwesomeIcon icon={faChevronDown} className="absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-500 pointer-events-none" />
//     </div>
// ));


const Contract = () => {
    const navigate = useNavigate();

    const [showModal, setShowModal] = useState(false);
    const [showReferModal, setShowReferModal] = useState(false);
    const [showLogoutModal, setShowLogoutModal] = useState(false);
    const [restaurantName] = useState('Sample Restaurant');
    const [ownerName] = useState('John Doe');
    // const [phoneNumber, setPhoneNumber] = useState('1234567890');
    const [businessAddress] = useState('123 Main St');
    const [gstNumber] = useState('123456789012');
    const [bankDetails] = useState('Bank Account Details');
    const [fssaiDetails] = useState('FSSAI Details');
    const [isChecked, setIsChecked] = useState(false); // State for checkbox
    const [isFormValid, setIsFormValid] = useState(false); // State for button enable/disable

    // Toggle Modal functions
    const toggleModal = () => setShowModal(!showModal);
    const toggleReferModal = () => setShowReferModal(!showReferModal);
    const handleLogoutClick = () => setShowLogoutModal(true);

    // Handle checkbox change
    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
        setIsFormValid(!isChecked); // Enable button if checkbox is checked
    };

    useEffect(() => {
        const token = localStorage.getItem('accessToken');
        if (!token) {
            navigate('/');
        }
    }, [navigate]);

    const handleNextClick = () => {
        if (isFormValid) {
            navigate('/sign-document');
        }
    };

    const confirmLogout = () => {
        setShowLogoutModal(false);
        logoutService('Session');
    };

    return (
        <div className="min-h-screen bg-gray-100 flex flex-col">

            <header className="fixed top-0 w-full bg-white shadow-md z-50 flex items-center justify-between">
                <div className="flex items-center ms-4 p-3">
                    <img src={Logo} alt="Logo" className="h-12" />
                </div>
                <div className="flex items-center me-4">
                    <button
                        className="bg-green-600 text-white py-2 px-10 rounded cursor-pointer"
                        onClick={handleLogoutClick}
                    >
                        LogOut
                    </button>
                </div>
            </header>

            <main className="flex-grow flex pt-20 pb-20 w-full">
                <div className="sidebar w-1/4 bg-gray-100 overflow-y-auto h-full p-6">
                    <div className="steps bg-white shadow-lg rounded-lg px-5 py-3 mb-6">
                        <h2 className="text-xl text-gray-600 font-semibold">Complete your registration</h2>
                        <hr className="-mx-5 border-gray-300" />
                        <ul className="mt-5 space-y-7">
                            <li className="flex items-center space-x-3">
                                <div className="text-2xl">&#127828;</div>
                                <div>
                                    <Link to="/register" className="doc-content"> Restaurant Information</Link>
                                    <p className="text-sm font-bold text-gray-500">Name, location and contact number</p>
                                </div>
                            </li>
                            <li className="flex items-center space-x-3">
                                <div className="text-2xl">&#128221;</div>
                                <div>
                                    <Link to="/menu-operational" className="doc-content"> Menu and Operational Details</Link>
                                </div>
                            </li>
                            <li className="flex items-center space-x-3">
                                <div className="text-2xl">&#128194;</div>
                                <div>
                                    <Link to="/restaurant-documents" className="doc-content"> Restaurant documents</Link>
                                </div>
                            </li>
                            <li className="flex items-center space-x-3">
                                <div className="text-2xl">&#128196;</div>
                                <div>
                                    <Link to="/contract" className="doc-content">Partner Contract</Link>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div className="docNotification flex justify-between items-center border p-3 rounded-lg cursor-pointer" onClick={toggleModal}>
                        <FontAwesomeIcon icon={faFileAlt} className="icons text-xl" />
                        <span className="text-black font-semibold me-8">Documents required for registration</span>
                        <div>
                            <FontAwesomeIcon icon={faPlay} className="icons text-base" />
                        </div>
                    </div>
                    <div className="refer flex justify-between items-center border p-3 rounded-lg mt-6 cursor-pointer" onClick={toggleReferModal}>
                        <p className="text-black font-semibold">Did someone refer you to this platform?</p>
                        <p className="yes font-semibold">Yes</p>
                    </div>
                </div>

                <div className="px-20 bg-gray-100 flex-grow">
                    <h3 className="mt-5 text-2xl font-bold"> Review Merchant Details</h3>

                    <label htmlFor="fullName" className="block mt-5 text-sm font-medium text-gray-700">Full Name*</label>
                    <input
                        type="text"
                        id="fullName"
                        value={restaurantName}
                        readOnly
                        className="mt-1 block w-full p-2 border rounded border-gray-300 shadow-sm bg-gray-200 focus:outline-none"
                    />

                    <label htmlFor="panNumber" className="block mt-5 text-sm font-medium text-gray-700">PAN Number*</label>
                    <input
                        type="text"
                        id="panNumber"
                        value={ownerName}
                        readOnly
                        className="mt-1 block w-full p-2 border rounded border-gray-300 shadow-sm bg-gray-200 focus:outline-none"
                    />

                    <label htmlFor="businessAddress" className="block mt-5 text-sm font-medium text-gray-700">Business Address</label>
                    <input
                        type="text"
                        id="businessAddress"
                        value={businessAddress}
                        readOnly
                        className="mt-1 block w-full p-2 border rounded border-gray-300 shadow-sm bg-gray-200 focus:outline-none"
                    />

                    <label htmlFor="gstNumber" className="block mt-5 text-sm font-medium text-gray-700">GST Number</label>
                    <input
                        type="text"
                        id="gstNumber"
                        value={gstNumber}
                        readOnly
                        className="mt-1 block w-full p-2 border rounded border-gray-300 shadow-sm bg-gray-200 focus:outline-none"
                    />

                    <label htmlFor="bankDetails" className="block mt-5 text-sm font-medium text-gray-700">Bank Account Details</label>
                    <input
                        type="text"
                        id="bankDetails"
                        value={bankDetails}
                        readOnly
                        className="mt-1 block w-full p-2 border rounded border-gray-300 shadow-sm bg-gray-200 focus:outline-none"
                    />

                    <label htmlFor="fssaiDetails" className="block mt-5 text-sm font-medium text-gray-700">FSSAI Details</label>
                    <input
                        type="text"
                        id="fssaiDetails"
                        value={fssaiDetails}
                        readOnly
                        className="mt-1 block w-full p-2 border rounded border-gray-300 shadow-sm bg-gray-200 focus:outline-none"
                    />

                    <p className="mt-5 text-2xl font-bold">In Genie, We are Providing Very Low Commission, Only 8%</p>

                    <form className="mt-5">
                        <div className="checkbox-container flex items-center">
                            <input
                                type="checkbox"
                                id="terms"
                                name="terms"
                                className="mr-2"
                                checked={isChecked}
                                onChange={handleCheckboxChange}
                            />
                            <label htmlFor="terms" className="text-sm">
                                I agree to the <a href="#" className="text-blue-500 underline">terms and conditions</a>
                            </label>
                        </div>
                    </form>


                    <button
                        className={'text-white text-center rounded h-10 w-44 mt-5 bg-green-600 cursor-pointer mr-4'}
                    >
                        Back
                    </button>


                    <button
                        onClick={handleNextClick}
                        disabled={!isFormValid}
                        className={`text-white text-center rounded h-10 w-36 mt-5  ${isFormValid ? 'bg-green-600' : 'bg-gray-400 cursor-not-allowed'}`}
                    >
                        Proceed to Sign
                    </button>

                </div>
            </main>

            {/* Modal code here */}

            {showModal && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                    <div className="bg-white rounded-lg shadow-lg p-5 w-full max-w-md">
                        <div className="mb-4">
                            <img src={Verified} alt="Verification" className="w-full rounded" />
                        </div>
                        <h2 className="text-xl font-semibold mb-3 text-center">Please be ready with the following for a smooth registration</h2>
                        <hr className="border-t border-gray-300 -mx-6" />
                        <ul className="space-y-3 mt-4">
                            <li className="flex items-center">
                                <FontAwesomeIcon icon={faCheckCircle} className="icons me-3" />
                                <span>PAN card</span>
                            </li>
                            <li className="flex items-center">
                                <FontAwesomeIcon icon={faCheckCircle} className="icons me-3" />
                                <span>FSSAI license</span>
                            </li>
                            <li className="flex items-center">
                                <FontAwesomeIcon icon={faCheckCircle} className="icons me-3" />
                                <span>Bank account details</span>
                            </li>
                            <li className="flex items-center">
                                <FontAwesomeIcon icon={faCheckCircle} className="icons me-3" />
                                <span>Menu details and one dish image</span>
                            </li>
                            <li className="flex items-center">
                                <FontAwesomeIcon icon={faCheckCircle} className="icons me-3" />
                                <span>GST number, if applicable</span>
                            </li>
                        </ul>
                        <hr className="border-t border-gray-300 mt-5 -mx-5" />
                        <div className="flex justify-center mt-3">
                            <button onClick={toggleModal} className="okay-btn text-white py-2 px-6 rounded-lg">Okay</button>
                        </div>
                    </div>
                </div>
            )}

            {showReferModal && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                    <div className="bg-white rounded-lg shadow-lg p-3 w-full max-w-sm">
                        <div className="flex justify-between items-center mb-4">
                            <h2 className="text-xl font-semibold">Email ID of referrer</h2>
                            <button onClick={toggleReferModal} className="text-gray-400 hover:text-gray-600">
                                <FontAwesomeIcon icon={faTimes} className="text-xl" />
                            </button>
                        </div>
                        <hr className="-mx-3 border-gray-300 mb-4" />
                        <div className="mb-4">
                            <input type="email" className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-1 focus:ring-lime-500" placeholder="Email id" />
                        </div>
                        <hr className="-mx-3 border-gray-300 mb-4" />
                        <div className="flex justify-center">
                            <button onClick={toggleReferModal} className="okay-btn text-white py-2 px-6 rounded-lg">Okay</button>
                        </div>
                    </div>
                </div>
            )}

            {showLogoutModal && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                    <div className="bg-white rounded-lg shadow-lg p-5 w-full max-w-md">
                        <h2 className="text-xl font-semibold mb-3 text-center">Are you sure you want to log out?</h2>
                        <div className="flex justify-center space-x-4 mt-5">
                            <button
                                className="bg-red-500 text-white py-2 px-6 rounded-lg"
                                onClick={confirmLogout}
                            >
                                Yes, Log Out
                            </button>
                            <button
                                className="bg-gray-300 text-black py-2 px-6 rounded-lg"
                                onClick={() => setShowLogoutModal(false)}
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            )}

        </div>
    );
};

export default Contract;





