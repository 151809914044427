// services/errorHandler.js
export const handleError = (error) => {
    if (error.response) {
      // Server responded with a status other than 200 range
      console.error('Error Response:', error.response.data);
      alert(`Error: ${error.response.data.message || 'Something went wrong!'}`);
    } else if (error.request) {
      // Request was made but no response received
      console.error('Error Request:', error.request);
      alert('Error: Network error, please try again later.');
    } else {
      // Something happened in setting up the request that triggered an Error
      console.error('Error Message:', error.message);
      alert('Error: Unexpected error, please try again later.');
    }
  };
  