import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faTimes } from '@fortawesome/free-solid-svg-icons';
import GoogleIcon from '../images/google-icon.svg';
import Otp from './Otp';
import Service from './Service';
import { sendOtp } from '../services/AuthApi';
import { handleError } from '../services/errorHandler';

const Login = ({ isOpen, onClose }) => {
    const [showOtpPage, setShowOtpPage] = useState(false);
    const [showServicePage, setShowServicePage] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState('');

    if (!isOpen && !showOtpPage && !showServicePage) {
        return null;
    }

    const phone = `+91${phoneNumber}`
    localStorage.setItem("phoneNumber", phone)

    // For sending OTP and showing the OTP modal
    const handleSendOTP = async (e) => {
        e.preventDefault();
        try {
            await sendOtp(phone);
            setShowOtpPage(true);
        } catch (error) {
            handleError(error);
        }
    };

    // Close both modals if onClose is called from OTP page
    const handleClose = () => {
        setShowOtpPage(false);
        setShowServicePage(false);
        onClose(); // For closing login page
    };

    // Shows service page when OTP timer ends
    const handleTimerEnd = () => {
        setShowOtpPage(false);
        setShowServicePage(true);
    };

    return (
        <>
            {showServicePage ? (
                <Service onClose={handleClose} />
            ) : showOtpPage ? (
                <Otp onClose={handleClose} onTimerEnd={handleTimerEnd} />
            ) : (
                <div className="fixed inset-0 flex items-center justify-center bg-gray-950 bg-opacity-75 z-50">
                    <div className="bg-white rounded-lg shadow-lg w-96 p-6">
                        <div className="flex justify-between items-center mb-4">
                            <h2 className="text-xl font-semibold">Login</h2>
                            <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
                                <FontAwesomeIcon icon={faTimes} />
                            </button>
                        </div>
                        <form className="space-y-4" onSubmit={handleSendOTP}>
                            <div className="flex items-center border rounded px-3 py-2">
                                <img src="https://upload.wikimedia.org/wikipedia/commons/b/bc/Flag_of_India.png" alt="India Flag" className="w-6 h-4 mr-2" />
                                <span className="text-gray-700">+91</span>
                                <input
                                    type="tel"
                                    placeholder="Phone"
                                    minLength="10"
                                    maxLength="10"
                                    className="ml-2 outline-none"
                                    value={phoneNumber}
                                    onChange={(e) => setPhoneNumber(e.target.value)}
                                    required
                                />
                            </div>
                            <button type="submit" className="w-full bg-red-500 hover:bg-red-600 text-white font-semibold rounded py-2">Send One Time Password</button>
                        </form>
                        <div className="flex items-center my-4">
                            <hr className="flex-grow border-gray-300" />
                            <span className="mx-2 text-gray-500">Or</span>
                            <hr className="flex-grow border-gray-300" />
                        </div>
                        <button className="w-full border border-gray-300 hover:bg-gray-100 text-gray-700 font-semibold flex items-center justify-center py-2 rounded">
                            <FontAwesomeIcon icon={faEnvelope} className="text-red-600 mr-2" />Continue with Email
                        </button>
                        <button className="w-full border border-gray-300 hover:bg-gray-100 text-gray-700 font-semibold flex items-center justify-center py-2 rounded mt-2">
                            <img src={GoogleIcon} alt="Google" className="mr-2 w-5 h-5" />
                            Sign in with Google
                        </button>
                        {/* <div className="text-center mt-4">
                            <p className="text-gray-500">New to Genie? <a href="#" className="text-red-500 hover:underline">Create account</a></p>
                        </div> */}
                    </div>
                </div>
            )}
        </>
    );
};

export default Login;