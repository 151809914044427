// services/AuthApi.js
import axios from "axios";

const BASE_URL = `${process.env.REACT_APP_API_ENDPOINT}/api`;

const ApiService = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});


export const sendOtp = async (phoneNumber) => {
    try {
      const response = await ApiService.post("/otp/send", { phoneNumber });
      return response.data;
    } catch (error) {
      throw error; // Will be caught by the error handler
    }
};
  
export const verifyOtp = async (phoneNumber, otp) => {
    try {
      const response = await ApiService.post("/otp/verify", {
        phoneNumber,
        otp,
        deviceType: "React_Onboarding_Merchant", // Default device type
        appVersion: "1.0.1", // Default app version
      });
      return response.data;
    } catch (error) {
      throw error;
    }
};
  
export const refreshTokenAPI = async (refreshToken) => {
    try {
      const response = await ApiService.post("/token/refresh", {
        refreshToken,
      });
      return response.data;
    } catch (error) {
      throw error;
    }
};