import React, { useState, useEffect, forwardRef } from 'react';
import Logo from '../images/logo.webp';
import './Registration.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAlt, faPlay, faCheckCircle, faTimes,  faChevronDown, faTrash, } from '@fortawesome/free-solid-svg-icons';
import Verified from '../images/verified.webp';
import { Link, useNavigate } from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';
import { logoutService } from '../services/LogoutService';

// Custom Input Component to restrict manual input
const CustomInput = forwardRef(({ value, onClick }, ref) => (
    <div className="relative w-full">
        <input
            className="block w-full border border-gray-300 outline-none rounded-md pl-3 pr-8 py-1"
            onClick={onClick}
            value={value}
            ref={ref}
            readOnly
        />
        <FontAwesomeIcon icon={faChevronDown} className="absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-500 pointer-events-none" />
    </div>
));

const Registration = () => {
    const navigate = useNavigate();

    // 1. Restaurant information
    // For displaying modal - Mandatory certificates
    const [showModal, setShowModal] = useState(false);

    // For displaying referal modal
    const [showReferModal, setShowReferModal] = useState(false);

    // For displaying otp modal - After clicking verify button
    const [showOtpModal, setShowOtpModal] = useState(false);
    const [otp, setOtp] = useState(new Array(6).fill(""));
    const [timer, setTimer] = useState(30);
    const [resendEnabled, setResendEnabled] = useState(false);

    // For setting phone number
    const [phoneNumber, setPhoneNumber] = useState('');
    const [primaryPhoneNumber, setPrimaryPhoneNumber] = useState('');

    // For setting owner number
    const [isSameAsOwner, setIsSameAsOwner] = useState(false);

    // Location
    const [latitude, setLatitude] = useState('28.6304');
    const [longitude, setLongitude] = useState('77.2177');

    // Restaurant details
    const [restaurantName, setRestaurantName] = useState('');
    const [ownerName, setOwnerName] = useState('');
    const [ownerEmail, setOwnerEmail] = useState('');
    const [restaurantAddress, setRestaurantAddress] = useState('');
    const [isFormValid, setIsFormValid] = useState(false);

    // Logout Modal
    const [showLogoutModal, setShowLogoutModal] = useState(false);

    // For displaying modal - Mandatory modal
    const toggleModal = () => {
        setShowModal(!showModal);
    };

    // For displaying referal modal
    const toggleReferModal = () => {
        setShowReferModal(!showReferModal);
    };

    // For setting owner number
    const handleCheckboxChange = () => {
        setIsSameAsOwner(!isSameAsOwner);
    };

    // For verifying phone number (allows only digits)
    const handlePhoneNumberChange = (e) => {
        const value = e.target.value;
        if (/^\d*$/.test(value) && value.length <= 10) {
            setPhoneNumber(value);
        }
    };

    const handlePrimaryPhoneNumberChange = (e) => {
        const value = e.target.value;
        if (/^\d*$/.test(value) && value.length <= 10) {
            setPrimaryPhoneNumber(value);
        }
    };

    // For jumping one box to another - otp
    const handleOtpChange = (element, index) => {
        if (/^\d*$/.test(element.value)) {
            let newOtp = [...otp];
            newOtp[index] = element.value;
            setOtp(newOtp);

            // Move focus to the next input box
            if (element.nextSibling && element.value) {
                element.nextSibling.focus();
            }
        }
    };

    // For decreasing time - otp
    const handleVerifyButtonClick = () => {
        if (phoneNumber.length === 10) {
            setShowOtpModal(true);
            setTimer(30);
            setResendEnabled(false);
        }
    };

    useEffect(() => {
        let interval;
        if (showOtpModal && timer > 0) {
            interval = setInterval(() => {
                setTimer(prevTimer => prevTimer - 1);
            }, 1000);
        } else if (timer === 0) {
            setResendEnabled(true);
            clearInterval(interval);
        }
        return () => clearInterval(interval);
    }, [showOtpModal, timer]);

    // Validate Form
    useEffect(() => {
        const isValid = restaurantName.trim() !== '' && ownerName.trim() !== '' && phoneNumber.length === 10;
        setIsFormValid(isValid);
    }, [restaurantName, ownerName, phoneNumber]);

    useEffect(() => {
        const token = localStorage.getItem('accessToken');
        if (!token) {
            navigate('/');
        }
    }, [navigate]);

    const handleNextClick = () => {
        if (isFormValid) {
            navigate('/menu-operational'); // Assuming this is the route for the next page
        } else {
            alert('Please fill all the required fields correctly.');
        }
    };

    const handleLogoutClick = () => {
        setShowLogoutModal(true);
    };

    const confirmLogout = () => {
        setShowLogoutModal(false);
        logoutService('Session');
    };

    return (

        <div className='registration bg-gray-100 h-screen flex'>

            <header className="fixed top-0 w-full bg-white shadow-md z-50 flex items-center justify-between">
                <div className="flex items-center ms-4 p-3">
                    <img src={Logo} alt="Logo" className="h-12" />
                </div>
                <div className="flex items-center me-4">
                    <button
                        className="bg-green-600 text-white py-2 px-10 rounded cursor-pointer"
                        onClick={confirmLogout}
                    >
                        LogOut
                    </button>
                </div>
            </header>

            <main className='pt-20 pb-20 flex w-full'>

                <div className='sidebar w-1/4 bg-gray-100 overflow-y-auto h-full p-6'>
                    <div className="steps bg-white shadow-lg rounded-lg px-5 py-3 mb-6">
                        <h2 className="text-xl text-gray-600 font-semibold">Complete your registration</h2>
                        <hr className="-mx-5 border-gray-300" />
                        <ul className="mt-5 space-y-7">
                            <li className="flex items-center space-x-3">
                                <div className="text-2xl">&#127828;</div>
                                <div>
                                    <Link to="/register" className="doc-content"> Restaurant Information</Link>
                                    <p className="text-sm font-bold text-gray-500">Name, location and contact number</p>
                                </div>
                            </li>
                            <li className="flex items-center space-x-3">
                                <div className="text-2xl">&#128221;</div>
                                <div>
                                    <Link to="/menu-operational" className="doc-content"> Menu and Operational Details</Link>
                                </div>
                            </li>
                            <li className="flex items-center space-x-3">
                                <div className="text-2xl">&#128194;</div>
                                <div>
                                    <Link to="/restaurant-documents" className="doc-content"> Restaurant documents</Link>
                                </div>
                            </li>
                            <li className="flex items-center space-x-3">
                                <div className="text-2xl">&#128196;</div>
                                <div>
                                    <Link to="/contract" className='doc-content'>Partner Contract</Link>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div className="docNotification flex justify-between items-center border p-3 rounded-lg cursor-pointer" onClick={toggleModal}>
                        <FontAwesomeIcon icon={faFileAlt} className='icons text-xl' />
                        <span className='text-black font-semibold me-8'>Documents required for registration</span>
                        <div>
                            <FontAwesomeIcon icon={faPlay} className='icons text-base' />
                        </div>
                    </div>
                    <div className="refer flex justify-between items-center border p-3 rounded-lg mt-6 cursor-pointer" onClick={toggleReferModal}>
                        <p className='text-black font-semibold'>Did someone refer you to this platform?</p>
                        <p className='yes font-semibold'>Yes</p>
                    </div>
                </div>

                <div className="content flex-1 bg-gray-100 overflow-y-auto p-6">
                    <div className="content flex flex-col py-3 m-4 content">
                        <h1 className="text-3xl font-bold mb-4">Restaurant information</h1>
                        <div className="bg-white shadow-lg rounded-lg p-5 w-full">
                            <h2 className="text-2xl font-bold ">Restaurant name</h2>
                            <p className="text-gray-600 font-medium text-sm mb-2">Customers will see this name on Genie</p>
                            <hr className='-mx-6' />
                            <form className='mt-3'>
                                <input
                                    type="text"
                                    value={restaurantName}
                                    onChange={(e) => setRestaurantName(e.target.value)}
                                    className="mt-2 p-2 w-full rounded-lg border border-gray-300 outline-none shadow-sm sm:text-sm"
                                    placeholder="Restaurant name*"
                                />
                            </form>
                        </div>
                        <div className="bg-white shadow-lg rounded-lg p-5 w-full  mt-4">
                            <h2 className="text-2xl font-bold">Owner details</h2>
                            <p className="text-sm font-medium text-gray-600 mb-2">Genie will use these details for all business communications and updates</p>
                            <hr className='-mx-6' />
                            <form className='mt-3'>
                                <div className="grid grid-cols-2 gap-4 mb-4">
                                    <input
                                        type="text"
                                        value={ownerName}
                                        onChange={(e) => setOwnerName(e.target.value)}
                                        className="mt-2 p-2 w-full rounded-md border border-gray-300 outline-none shadow-sm sm:text-sm"
                                        placeholder="Full name*"
                                    />
                                    <input
                                        type="email"
                                        value={ownerEmail}
                                        onChange={(e) => setOwnerEmail(e.target.value)}
                                        className="mt-2 p-2 w-full rounded-md border border-gray-300 outline-none shadow-sm sm:text-sm"
                                        placeholder="Email address (optional)"
                                    />
                                </div>
                                <div className="mb-4">
                                    <div className="flex items-center border border-gray-300 outline-none shadow-sm sm:text-sm rounded p-2">
                                        <div className="flex items-center">
                                            <img src="https://upload.wikimedia.org/wikipedia/commons/b/bc/Flag_of_India.png" alt="India Flag" className="w-6 h-4 mr-2" />
                                            <span className="text-gray-700">+91</span>
                                        </div>
                                        <input
                                            type="tel"
                                            placeholder="Phone number*"
                                            value={phoneNumber}
                                            onChange={handlePhoneNumberChange}
                                            minLength="10"
                                            maxLength="10"
                                            className="ml-2 outline-none flex-1 border-l border-gray-300 pl-2"
                                            required
                                        />
                                        {phoneNumber.length === 10 && (
                                            <button className="verify-btn text-white ml-2 px-3 py-1 rounded" onClick={handleVerifyButtonClick}>Verify</button>
                                        )}
                                    </div>
                                </div>
                                <div className="mb-4">
                                    <label className="text-lg font-medium">Restaurant's primary contact number</label>
                                    <p className="text-sm text-gray-500 mb-2">Customers, delivery partners and Genie may call on this number for order support</p>
                                    <div className="flex items-center border border-gray-300 rounded-md mt-2">
                                        <input type="checkbox" className="h-4 w-4 text-indigo-600 ml-2 border-gray-300 rounded" checked={isSameAsOwner} onChange={handleCheckboxChange} />
                                        <label className="ml-1 w-full p-2 text-sm text-gray-600">Same as owner mobile number</label>
                                    </div>
                                    {!isSameAsOwner && (
                                        <div className="flex items-center border border-gray-300 outline-none shadow-sm sm:text-sm rounded p-2 mt-2">
                                            <div className="flex items-center">
                                                <img src="https://upload.wikimedia.org/wikipedia/commons/b/bc/Flag_of_India.png" alt="India Flag" className="w-6 h-4 mr-2" />
                                                <span className="text-gray-700">+91</span>
                                            </div>
                                            <input
                                                type="tel"
                                                placeholder="Phone number*"
                                                value={primaryPhoneNumber}
                                                onChange={handlePrimaryPhoneNumberChange}
                                                minLength="10"
                                                maxLength="10"
                                                className="ml-2 outline-none flex-1 border-l border-gray-300 pl-2"
                                                required
                                            />
                                            {primaryPhoneNumber.length === 10 && (
                                                <button className="ml-2 bg-blue-500 text-white px-3 py-1 rounded">Verify</button>
                                            )}
                                        </div>
                                    )}
                                </div>
                            </form>
                        </div>
                        <div className="bg-white shadow-lg rounded-lg px-5 pt-5 w-full mt-4">
                            <h2 className="text-2xl font-bold">Restaurant address details</h2>
                            <p className="text-sm font-medium text-gray-600 mb-6">Address details are based on the restaurant location mentioned above</p>
                            <form>
                                <div className="grid grid-cols-2 gap-4 mb-4">
                                    <div>
                                        <label className="text-sm text-gray-500">Enter Latitudinal value</label>
                                        <input
                                            type="text"
                                            value={latitude}
                                            readOnly
                                            className="mt-1 p-2 w-full rounded-md border border-gray-300 outline-none shadow-sm sm:text-sm"
                                        />
                                    </div>
                                    <div>
                                        <label className="text-sm text-gray-500">Enter Longitudinal value</label>
                                        <input
                                            type="text"
                                            value={longitude}
                                            readOnly
                                            className="mt-1 p-2 w-full rounded-md border border-gray-300 outline-none shadow-sm sm:text-sm"
                                        />
                                    </div>
                                </div>
                                <div className="grid grid-cols-2 gap-4 mb-4">
                                    <input
                                        type="text"
                                        placeholder="Shop no. / building no. (optional)"
                                        className="mt-2 p-2 w-full rounded-md border border-gray-300 outline-none shadow-sm sm:text-sm"
                                    />
                                    <input
                                        type="text"
                                        placeholder="Floor / tower (optional)"
                                        className="mt-2 p-2 w-full rounded-md border border-gray-300 outline-none shadow-sm sm:text-sm"
                                    />
                                </div>
                                <div className="grid grid-cols-2 gap-4 mb-4">
                                    <input
                                        type="text"
                                        placeholder="Area / Sector / Locality*"
                                        className="mt-2 p-2 w-full rounded-md border border-gray-300 outline-none shadow-sm sm:text-sm"
                                    />
                                    <div className="relative">
                                        <input
                                            type="text"
                                            value="Delhi NCR"
                                            readOnly
                                            className="mt-2 p-2 w-full rounded-md border border-gray-300 outline-none shadow-sm sm:text-sm"
                                            placeholder=" "
                                        />
                                    </div>
                                </div>
                                <input
                                    type="text"
                                    placeholder="Add any nearby landmark (optional)"
                                    className="mt-2 p-2 w-full rounded-md border border-gray-300 outline-none shadow-sm sm:text-sm"
                                />
                                <p className="address-note text-sm px-1 py-2 rounded-md mt-4 -mx-5">
                                    Please ensure that this address is the same as mentioned on your FSSAI license
                                </p>
                            </form>
                        </div>
                    </div>
                </div>
            </main>
            

            <footer className="fixed bottom-0 w-full bg-white shadow-md z-50">

                <div className="flex justify-end p-4">
                    <a href="">
                        <button className="bg-slate-500 text-white py-2 px-10 rounded cursor-not-allowed opacity-50 me-16" disabled>Save As Draft</button>
                    </a>
                    <button
                        className={`bg-green-600 text-white py-2 px-10 rounded ${isFormValid ? 'cursor-pointer' : 'cursor-not-allowed opacity-50'}`}
                        onClick={handleNextClick}
                        disabled={!isFormValid}
                    >
                        Next
                    </button>
                </div>
            </footer>

            {/* Modal code here */}

            {showModal && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                    <div className="bg-white rounded-lg shadow-lg p-5 w-full max-w-md">
                        <div className="mb-4">
                            <img src={Verified} alt="Verification" className="w-full rounded" />
                        </div>
                        <h2 className="text-xl font-semibold mb-3 text-center">Please be ready with the following for a smooth registration</h2>
                        <hr className="border-t border-gray-300 -mx-6" />
                        <ul className="space-y-3 mt-4">
                            <li className="flex items-center">
                                <FontAwesomeIcon icon={faCheckCircle} className="icons me-3" />
                                <span>PAN card</span>
                            </li>
                            <li className="flex items-center">
                                <FontAwesomeIcon icon={faCheckCircle} className="icons me-3" />
                                <span>FSSAI license</span>
                            </li>
                            <li className="flex items-center">
                                <FontAwesomeIcon icon={faCheckCircle} className="icons me-3" />
                                <span>Bank account details</span>
                            </li>
                            <li className="flex items-center">
                                <FontAwesomeIcon icon={faCheckCircle} className="icons me-3" />
                                <span>Menu details and one dish image</span>
                            </li>
                            <li className="flex items-center">
                                <FontAwesomeIcon icon={faCheckCircle} className="icons me-3" />
                                <span>GST number, if applicable</span>
                            </li>
                        </ul>
                        <hr className="border-t border-gray-300 mt-5 -mx-5" />
                        <div className="flex justify-center mt-3">
                            <button onClick={toggleModal} className="okay-btn text-white py-2 px-6 rounded-lg">Okay</button>
                        </div>
                    </div>
                </div>
            )}

            {showReferModal && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                    <div className="bg-white rounded-lg shadow-lg p-3 w-full max-w-sm">
                        <div className="flex justify-between items-center mb-4">
                            <h2 className="text-xl font-semibold">Email ID of referrer</h2>
                            <button onClick={toggleReferModal} className="text-gray-400 hover:text-gray-600">
                                <FontAwesomeIcon icon={faTimes} className="text-xl" />
                            </button>
                        </div>
                        <hr className="-mx-3 border-gray-300 mb-4" />
                        <div className="mb-4">
                            <input type="email" className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-1 focus:ring-lime-500" placeholder="Email id" />
                        </div>
                        <hr className="-mx-3 border-gray-300 mb-4" />
                        <div className="flex justify-center">
                            <button onClick={toggleReferModal} className="okay-btn text-white py-2 px-6 rounded-lg">Okay</button>
                        </div>
                    </div>
                </div>
            )}

            {showOtpModal && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                    <div className="bg-white rounded-lg shadow-lg p-3 w-full max-w-sm">
                        <div className="flex justify-between items-center mb-4">
                            <h2 className="text-xl font-semibold">Enter verification code</h2>
                            <button onClick={() => setShowOtpModal(false)} className="text-gray-400 hover:text-gray-600">
                                <FontAwesomeIcon icon={faTimes} className="text-xl" />
                            </button>
                        </div>
                        <p className="text-center text-gray-600 mb-4">6 digit OTP has been sent to +91 {phoneNumber}</p>
                        <div className="flex justify-center space-x-2 mb-4">
                            {otp.map((data, index) => (
                                <input
                                    key={index}
                                    type="text"
                                    maxLength="1"
                                    className="w-10 h-10 text-center border border-gray-300 rounded-lg"
                                    value={data}
                                    onChange={(e) => handleOtpChange(e.target, index)}
                                />
                            ))}
                        </div>
                        {timer > 0 ? (
                            <>
                                <p className="text-center text-gray-600 mb-4">Resend OTP in ({timer}) seconds</p>
                                <button
                                    className={`w-full py-2 rounded-lg text-white ${otp.every(val => val) ? 'verify-btn' : 'bg-gray-300 cursor-not-allowed'}`}
                                    disabled={!otp.every(val => val)}
                                >
                                    Verify
                                </button>
                            </>
                        ) : (
                            <button
                                className="w-full py-2 rounded-lg text-white resend-btn"
                                onClick={() => {
                                    setTimer(30);
                                    setResendEnabled(false);
                                }}
                            >
                                Resend OTP
                            </button>
                        )}
                    </div>
                </div>
            )}

            {showLogoutModal && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                    <div className="bg-white rounded-lg shadow-lg p-5 w-full max-w-md">
                        <h2 className="text-xl font-semibold mb-3 text-center">Are you sure you want to log out?</h2>
                        <div className="flex justify-center space-x-4 mt-5">
                            <button
                                className="bg-red-500 text-white py-2 px-6 rounded-lg"
                                onClick={confirmLogout}
                            >
                                Yes, Log Out
                            </button>
                            <button
                                className="bg-gray-300 text-black py-2 px-6 rounded-lg"
                                onClick={() => setShowLogoutModal(false)}
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            )}

        </div>
    );
};

export default Registration;

