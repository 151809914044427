import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faFileAlt, faCheckCircle, faPlay } from '@fortawesome/free-solid-svg-icons';
import { GrGallery } from 'react-icons/gr';
import { FaCalendarAlt } from 'react-icons/fa';
import { useNavigate, Link } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Logo from '../images/logo.webp';
import Verified from '../images/verified.webp';
import UploadGuidelinesModal from '../components/UploadGuidelinesModal';
import UploadGstGuidelines from './UploadGstGuidelines';
import { logoutService } from '../services/LogoutService';
import UploadFssaiGuidelines from './UploadFssaiGuidelines';
import {
    fetchPanUrl,
    deletePanUrl,
    fetchGstUrl,
    deleteGstUrl,
    fetchFssaiUrl,
    deleteFssaiUrl,
    confirmPanUpload,
    confirmGstUpload,
    confirmFssaiUpload,
    uploadPanImage,
    uploadGstImage,
    uploadFssaiImage
} from '../services/Api';
import imageCompression from 'browser-image-compression';
import './Registration.css';

const RestaurantDocuments = () => {
    const [showModal, setShowModal] = useState(false);
    const [showReferModal, setShowReferModal] = useState(false);
    const [panNumber, setPanNumber] = useState('');
    const [fullName, setFullName] = useState('');
    const [address, setAddress] = useState('');
    const [panVerified, setPanVerified] = useState(false);
    const [panInvalid, setPanInvalid] = useState(false);
    const [isGstRegistered, setIsGstRegistered] = useState(null);
    const [fssaiNumber, setFssaiNumber] = useState('');
    const [expiryDate, setExpiryDate] = useState(null);
    const [uploadedPanImage, setUploadedPanImage] = useState(null);
    const [uploadedGstImage, setUploadedGstImage] = useState(null);
    const [uploadedFssaiImage, setUploadedFssaiImage] = useState(null);
    const [panVerificationStatus, setPanVerificationStatus] = useState(null);
    const [gstVerificationStatus, setGstVerificationStatus] = useState(null);
    const [fssaiVerificationStatus, setFssaiVerificationStatus] = useState(null);
    const [isPanModalOpen, setIsPanModalOpen] = useState(false);
    const [isGSTModalOpen, setIsGSTModalOpen] = useState(false);
    const [isFssaiModalOpen, setIsFssaiModalOpen] = useState(false);
    const datepickerRef = useRef(null);
    const [accountNumber, setAccountNumber] = useState('');
    const [reEnterAccountNumber, setReEnterAccountNumber] = useState('');
    const [ifscCode, setIfscCode] = useState('');
    const [accountType, setAccountType] = useState('');
    const [isFormValid, setIsFormValid] = useState(false);
    // const [showLogoutConfirm, setShowLogoutConfirm] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        validateForm();
    }, [panNumber, fullName, address, isGstRegistered, fssaiNumber, expiryDate, accountNumber, reEnterAccountNumber, ifscCode, accountType]);

    useEffect(() => {
        fetchDocumentUrls();
    }, []);

    const fetchDocumentUrls = async () => {
        try {
            const panData = await fetchPanUrl();
            const gstData = await fetchGstUrl();
            const fssaiData = await fetchFssaiUrl();

            setUploadedPanImage(panData.pan_url || null);
            setUploadedGstImage(gstData.gst_url || null);
            setUploadedFssaiImage(fssaiData.fssai_url || null);
        } catch (error) {
            console.error('Error fetching document URLs:', error);
        }
    };

    const toggleModal = () => setShowModal(!showModal);
    const toggleReferModal = () => setShowReferModal(!showReferModal);
    // Logout Modal
    const [showLogoutModal, setShowLogoutModal] = useState(false);

    const handleLogoutClick = () => {
        setShowLogoutModal(true);
    };

    const confirmLogout = () => {
        setShowLogoutModal(false);
        logoutService('Session');
    };

    const handlePanVerify = () => {
        const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
        if (panRegex.test(panNumber)) {
            setPanVerified(true);
            setPanInvalid(false);
        } else {
            setPanVerified(false);
            setPanInvalid(true);
        }
    };

    const handleImageUpload = async (e, uploadFunction, confirmUploadFunction) => {
        const file = e.target.files[0];
        let metadataId;

        try {
            const options = {
                maxSizeMB: 1,
                maxWidthOrHeight: 1024,
                fileType: 'image/webp',
            };

            const webpFile = await imageCompression(file, options);
            const { presignedURL, metadataId: id } = await uploadFunction('webp');
            metadataId = id;

            await fetch(presignedURL, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'image/webp',
                },
                body: webpFile,
            });

            await confirmUploadFunction(metadataId, true);

            alert('Image uploaded and confirmed successfully');
            return URL.createObjectURL(webpFile);
        } catch (error) {
            console.error('Error uploading file:', error);
            if (metadataId) {
                await confirmUploadFunction(metadataId, false);
            }
            alert('Failed to upload the image');
        }
    };

    const handlePanImageUpload = async (e) => {
        const imageUrl = await handleImageUpload(e, uploadPanImage, confirmPanUpload);
        setUploadedPanImage(imageUrl);
        setPanVerificationStatus('verified');
    };

    const handleGstImageUpload = async (e) => {
        const imageUrl = await handleImageUpload(e, uploadGstImage, confirmGstUpload);
        setUploadedGstImage(imageUrl);
        setGstVerificationStatus('verified');
    };

    const handleFssaiImageUpload = async (e) => {
        const imageUrl = await handleImageUpload(e, uploadFssaiImage, confirmFssaiUpload);
        setUploadedFssaiImage(imageUrl);
        setFssaiVerificationStatus('verified');
    };

    const handleDeleteImage = async (deleteFunction, imageUrlSetter, verificationStatusSetter) => {
        try {
            await deleteFunction();
            imageUrlSetter(null);
            verificationStatusSetter(null);
            alert("Image deleted Successfully")
            setUploadedPanImage(null);
            setUploadedGstImage(null);
            setUploadedFssaiImage(null);
        } catch (error) {
            console.error('Error deleting image:', error);
        }
    };

    const validateForm = () => {
        const isAccountNumberValid = accountNumber.length >= 9 && accountNumber.length <= 18;
        const isReEnterAccountNumberValid = accountNumber === reEnterAccountNumber;
        const isIfscCodeValid = /^[A-Z]{4}0[A-Z0-9]{6}$/.test(ifscCode);
        const isAccountTypeValid = accountType !== '';
        const isPanDetailsValid = panVerified && fullName && address;
        const isFssaiDetailsValid = fssaiNumber && expiryDate && fssaiVerificationStatus === 'verified';
        const isGstDetailsValid = (isGstRegistered !== 'yes') || (uploadedGstImage && gstVerificationStatus === 'verified');

        setIsFormValid(
            isAccountNumberValid &&
            isReEnterAccountNumberValid &&
            isIfscCodeValid &&
            isAccountTypeValid &&
            isPanDetailsValid &&
            isFssaiDetailsValid &&
            isGstDetailsValid
        );
    };

    const handleAccountNumberChange = (e) => {
        setAccountNumber(e.target.value);
    };

    const handleReEnterAccountNumberChange = (e) => {
        setReEnterAccountNumber(e.target.value);
    };

    const handleIfscCodeChange = (e) => {
        setIfscCode(e.target.value);
    };

    const handleAccountTypeChange = (e) => {
        setAccountType(e.target.value);
    };

    const handleNextClick = () => {
        if (isFormValid) {
            navigate('/contract');
        }
    };

    useEffect(() => {
        const token = localStorage.getItem('accessToken');
        if (!token) {
            navigate('/');
        }
    }, [navigate]);

    return (
        <div className='registration bg-gray-100 h-screen flex'>

            <header className="fixed top-0 w-full bg-white shadow-md z-50 flex items-center justify-between">
                <div className="flex items-center ms-4 p-3">
                    <img src={Logo} alt="Logo" className="h-12" />
                </div>
                <div className="flex items-center me-4">
                    <button
                        className="bg-green-600 text-white py-2 px-10 rounded cursor-pointer"
                        onClick={handleLogoutClick}
                    >
                        LogOut
                    </button>
                </div>
            </header>


            <main className='pt-20 pb-20 flex w-full'>

                <div className='sidebar w-1/4 bg-gray-100 overflow-y-auto h-full p-6'>
                    <div className="steps bg-white shadow-lg rounded-lg px-5 py-3 mb-6">
                        <h2 className="text-xl text-gray-600 font-semibold mb-3">Complete your registration</h2>
                        <hr className="-mx-5 border-gray-300" />
                        <ul className="mt-5 space-y-7">
                            <li className="flex items-center space-x-3">
                                <div className="text-2xl">&#127828;</div>
                                <div>
                                    <Link to="/register" className="doc-content"> Restaurant Information</Link>
                                    <p className="text-sm font-bold text-gray-500">Name, location and contact number</p>
                                </div>
                            </li>
                            <li className="flex items-center space-x-3">
                                <div className="text-2xl">&#128221;</div>
                                <div>
                                    <Link to="/menu-operational" className="doc-content"> Menu and Operational Details</Link>
                                </div>
                            </li>
                            <li className="flex items-center space-x-3">
                                <div className="text-2xl">&#128194;</div>
                                <div>
                                    <Link to="/restaurant-documents" className="doc-content"> Restaurant documents</Link>
                                </div>
                            </li>
                            <li className="flex items-center space-x-3">
                                <div className="text-2xl">&#128196;</div>
                                <div>
                                    <Link to="/contract" className='doc-content'>Partner Contract</Link>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div className="docNotification flex justify-between items-center border p-3 rounded-lg cursor-pointer" onClick={toggleModal}>
                        <FontAwesomeIcon icon={faFileAlt} className='icons text-xl' />
                        <span className='text-black font-semibold me-8'>Documents required for registration</span>
                        <div>
                            <FontAwesomeIcon icon={faPlay} className='icons text-base' />
                        </div>
                    </div>
                    <div className="refer flex justify-between items-center border p-3 rounded-lg mt-6 cursor-pointer" onClick={toggleReferModal}>
                        <p className='text-black font-semibold'>Did someone refer you to this platform?</p>
                        <p className='yes font-semibold'>Yes</p>
                    </div>
                </div>

                <div className="content flex-1 overflow-y-auto p-6 bg-gray-100">

                    <div className="container mx-auto p-6">
                        {/* PAN Details Section */}
                        <div className="bg-white p-6 rounded-lg shadow-md">
                            <h2 className="text-2xl font-bold mb-4">PAN details</h2>
                            <p className="text-gray-600 mb-6">Enter the PAN details of the person or company who legally owns the restaurant</p>
                            <div className="grid grid-cols-1 gap-6 mb-6">
                                <div className="flex items-center space-x-4">
                                    <div className="flex-1">
                                        <label htmlFor="panNumber" className="block text-sm font-medium text-gray-700">PAN number*</label>
                                        <input
                                            type="text"
                                            id="panNumber"
                                            value={panNumber}
                                            onChange={(e) => setPanNumber(e.target.value)}
                                            className={`mt-1 block w-full p-2 border ${panInvalid ? 'border-red-500' : 'border-gray-300'} rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500`}
                                        />
                                        {panInvalid && <p className="text-red-500 text-sm mt-1">Invalid PAN number</p>}
                                    </div>
                                    <div className="flex-1">
                                        <label htmlFor="fullName" className="block text-sm font-medium text-gray-700">Full name as per PAN*</label>
                                        <input
                                            type="text"
                                            id="fullName"
                                            value={fullName}
                                            onChange={(e) => setFullName(e.target.value)}
                                            className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                                        />
                                    </div>
                                    <button
                                        onClick={handlePanVerify}
                                        className="mt-6 bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
                                    >
                                        Verify
                                    </button>
                                </div>
                                {panVerified && <div className="text-green-500 flex items-center"><span className="material-icons"> Pan Details Verified </span> </div>}
                            </div>




                            <div className="border-2 border-dashed border-gray-300 p-6 rounded-lg text-center">
                                {uploadedPanImage ? (
                                    <div className="relative">
                                        <img src={uploadedPanImage} alt="Uploaded" className="mx-auto mb-4 max-h-48" />
                                        <button
                                            className="absolute top-2 right-2 text-red-500 bg-white rounded-full p-2 shadow-lg"
                                            onClick={() => handleDeleteImage(() => deletePanUrl(uploadedPanImage), setUploadedPanImage, setPanVerificationStatus)}
                                        >
                                            <FontAwesomeIcon icon={faTimes} />
                                        </button>
                                    </div>
                                ) : (
                                    <div className="flex justify-center items-center mb-4">
                                        <GrGallery className="text-green-500 text-3xl" />
                                    </div>
                                )}
                                <p className="text-green-500 font-bold">Upload your PAN</p>
                                <p className="text-gray-600">jpeg, png or pdf formats up-to 5MB</p>
                                <input type="file" onChange={handlePanImageUpload} className="mt-2" />
                            </div>



                            <div className="mt-4 text-center">
                                <button onClick={() => setIsPanModalOpen(true)} className="text-green-500 underline">Guidelines to upload PAN</button>
                            </div>
                        </div>

                        <UploadGuidelinesModal isOpen={isPanModalOpen} onClose={() => setIsPanModalOpen(false)} />





                        <div className="bg-white rounded-lg shadow-md mt-6">
                            <div className='container mx-auto p-6'>
                                <div>
                                    <h4 className="text-xl font-bold mb-4">Full address of your registered business*</h4>

                                    <input
                                        type="text"
                                        id="address"
                                        value={address}
                                        onChange={(e) => setAddress(e.target.value)}
                                        className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                                    />
                                </div>

                            </div>
                        </div>




                        {/* GST Details Section */}
                        <div className="bg-white p-6 rounded-lg shadow-md mt-6">
                            <p className="text-sm text-gray-600 mb-2">Note: Please first enter and verify all the PAN details above.</p>
                            <h2 className="text-2xl font-bold mb-2">GST details (if applicable)</h2>
                            <p className="text-gray-600 mb-6">This should be linked to the PAN provided earlier for tax calculations</p>
                            <p className="mb-4">Are you GST registered?</p>
                            <div className="flex items-center mb-6">
                                <label className="flex items-center mr-4">
                                    <input
                                        type="radio"
                                        name="gstRegistered"
                                        value="yes"
                                        checked={isGstRegistered === 'yes'}
                                        onChange={() => setIsGstRegistered('yes')}
                                        className="form-radio text-blue-500"
                                    />
                                    <span className="ml-2">Yes</span>
                                </label>
                                <label className="flex items-center">
                                    <input
                                        type="radio"
                                        name="gstRegistered"
                                        value="no"
                                        checked={isGstRegistered === 'no'}
                                        onChange={() => setIsGstRegistered('no')}
                                        className="form-radio text-blue-500"
                                    />
                                    <span className="ml-2">No</span>
                                </label>
                            </div>
                            {isGstRegistered === 'yes' && (
                                <>
                                    <div className="mt-4">
                                        <label htmlFor="gstNumber" className="block text-sm font-medium text-gray-700">GST Number*</label>
                                        <input
                                            type="text"
                                            id="gstNumber"
                                            className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-green-500 focus:border-green-500"
                                        />
                                    </div>

                                    <div className="border-2 border-dashed border-gray-300 p-6 rounded-lg text-center mt-6">
                                        {uploadedGstImage ? (
                                            <div className="relative">
                                                <img src={uploadedGstImage} alt="Uploaded" className="mx-auto mb-4 max-h-48" />
                                                <button
                                                    className="absolute top-2 right-2 text-red-500 bg-white rounded-full p-2 shadow-lg"
                                                    onClick={() => handleDeleteImage(() => deleteGstUrl(uploadedGstImage), setUploadedGstImage, setGstVerificationStatus)}
                                                >
                                                    <FontAwesomeIcon icon={faTimes} />
                                                </button>
                                            </div>
                                        ) : (
                                            <>
                                                <div className="flex justify-center items-center mb-4">
                                                    <GrGallery className='text-green-500 text-3xl' />
                                                </div>
                                                <p className="text-green-500 font-bold">Upload your GST Certificate</p>
                                                <p className="text-gray-600">jpeg, png or pdf formats up-to 5MB</p>
                                            </>
                                        )}
                                        <input type="file" onChange={handleGstImageUpload} className="mt-2" />
                                    </div>
                                    <div className="mt-4 text-center">
                                        <button onClick={() => setIsGSTModalOpen(true)} className="text-green-500 underline">Guidelines to upload GST</button>
                                    </div>
                                    <UploadGstGuidelines isOpen={isGSTModalOpen} onClose={() => setIsGSTModalOpen(false)} />
                                </>
                            )}
                        </div>

                        {/* FSSAI Details Section */}
                        <div className="container mx-auto mt-5">
                            <h1 className="text-3xl font-bold mb-4">FSSAI details</h1>
                            <div className="bg-white p-6 rounded-lg shadow-md">
                                <h2 className="text-2xl font-bold mb-4">FSSAI details</h2>
                                <p className="text-gray-600 mb-6">This is required to comply with regulations on food safety</p>
                                <div className="bg-blue-50 p-4 rounded-lg mb-6">
                                    <h3 className="font-bold text-lg mb-2">FSSAI requirements</h3>
                                    <ul className="list-disc list-inside">
                                        <li>The name on the FSSAI certificate should match either the restaurant's name or the name on the PAN card.</li>
                                        <li>The address on the FSSAI certificate should match the restaurant's address.</li>
                                    </ul>
                                </div>
                                <div className="grid grid-cols-1 gap-6 mb-6">
                                    <div className="flex-1">
                                        <label htmlFor="fssaiNumber" className="block text-sm font-medium text-gray-700">FSSAI number*</label>
                                        <input
                                            type="text"
                                            id="fssaiNumber"
                                            value={fssaiNumber}
                                            onChange={(e) => setFssaiNumber(e.target.value)}
                                            className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-green-500 focus:border-green-500"
                                        />
                                    </div>
                                    <div className="flex-1 relative">
                                        <label htmlFor="expiryDate" className="block text-sm font-medium text-gray-700">Expiry date*</label>
                                        <div className="mt-1 relative">
                                            <DatePicker
                                                ref={datepickerRef}
                                                selected={expiryDate}
                                                onChange={(date) => setExpiryDate(date)}
                                                dateFormat="dd/MM/yyyy"
                                                className="block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-green-500 focus:border-green-500"
                                            />
                                            <FaCalendarAlt
                                                className="absolute top-3 left-44 text-gray-400 cursor-pointer"
                                                onClick={() => datepickerRef.current.setFocus()}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="border-2 border-dashed border-gray-300 p-6 rounded-lg text-center mb-6">
                                    {uploadedFssaiImage ? (
                                        <div className="relative">
                                            <img src={uploadedFssaiImage} alt="Uploaded" className="mx-auto mb-4 max-h-48" />
                                            <button
                                                className="absolute top-2 right-2 text-red-500 bg-white rounded-full p-2 shadow-lg"
                                                onClick={() => handleDeleteImage(() => deleteFssaiUrl(uploadedFssaiImage), setUploadedFssaiImage, setFssaiVerificationStatus)}
                                            >
                                                <FontAwesomeIcon icon={faTimes} />
                                            </button>
                                        </div>
                                    ) : (
                                        <>
                                            <div className="flex justify-center items-center mb-4">
                                                <GrGallery className='text-green-500 text-3xl' />
                                            </div>
                                            <p className="text-green-500 font-bold">Upload your FSSAI license</p>
                                            <p className="text-gray-600">jpeg, png or pdf formats up-to 5MB</p>
                                        </>
                                    )}
                                    <input type="file" onChange={handleFssaiImageUpload} className="mt-2" />
                                </div>
                                <div className="mt-4 text-center">
                                    <button onClick={() => setIsFssaiModalOpen(true)} className="text-green-500 underline">FSSAI guidelines to upload</button>
                                </div>
                            </div>
                            <UploadFssaiGuidelines isOpen={isFssaiModalOpen} onClose={() => setIsFssaiModalOpen(false)} />
                        </div>

                        {/* Bank Account Details Section */}
                        <div className="container mx-auto mt-5">
                            <h1 className="text-3xl font-bold mb-4">Bank account details</h1>
                            <p className="text-gray-600 mb-4">This is where Genie will deposit your earnings</p>
                            <div className="bg-white p-6 rounded-lg shadow-md">
                                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
                                    <div>
                                        <label htmlFor="accountNumber" className="block text-sm font-medium text-gray-700">Bank account number*</label>
                                        <input
                                            type="text"
                                            id="accountNumber"
                                            value={accountNumber}
                                            onChange={handleAccountNumberChange}
                                            className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-green-500 focus:border-green-500"
                                        />
                                        {accountNumber.length > 0 && (accountNumber.length < 9 || accountNumber.length > 18) && (
                                            <p className="text-red-500 text-sm mt-1">Invalid account number</p>
                                        )}
                                    </div>
                                    <div>
                                        <label htmlFor="reEnterAccountNumber" className="block text-sm font-medium text-gray-700">Re-enter bank account number*</label>
                                        <input
                                            type="text"
                                            id="reEnterAccountNumber"
                                            value={reEnterAccountNumber}
                                            onChange={handleReEnterAccountNumberChange}
                                            className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-green-500 focus:border-green-500"
                                        />
                                        {reEnterAccountNumber.length > 0 && reEnterAccountNumber !== accountNumber && (
                                            <p className="text-red-500 text-sm mt-1">Account numbers do not match</p>
                                        )}
                                    </div>
                                    <div>
                                        <label htmlFor="ifscCode" className="block text-sm font-medium text-gray-700">Enter IFSC code*</label>
                                        <input
                                            type="text"
                                            id="ifscCode"
                                            value={ifscCode}
                                            onChange={handleIfscCodeChange}
                                            className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-green-500 focus:border-green-500"
                                        />
                                        {ifscCode.length > 0 && !/^[A-Z]{4}0[A-Z0-9]{6}$/.test(ifscCode) && (
                                            <p className="text-red-500 text-sm mt-1">Invalid IFSC code</p>
                                        )}
                                    </div>
                                    <div>
                                        <label htmlFor="accountType" className="block text-sm font-medium text-gray-700">Account Type</label>
                                        <select
                                            id="accountType"
                                            value={accountType}
                                            onChange={handleAccountTypeChange}
                                            className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-green-500 focus:border-green-500"
                                        >
                                            <option value="">Select Account Type</option>
                                            <option value="savings">Savings</option>
                                            <option value="current">Current</option>
                                        </select>
                                        {accountType === '' && (
                                            <p className="text-red-500 text-sm mt-1">Please select account type</p>
                                        )}
                                    </div>
                                </div>
                                <button
                                    onClick={handleNextClick}
                                    disabled={!isFormValid}
                                    className={`mt-6 w-full py-2 rounded ${isFormValid ? 'bg-green-500 text-white' : 'bg-gray-300 text-gray-500 cursor-not-allowed'}`}
                                >
                                    Verify bank account details
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </main>

            <footer className="fixed bottom-0 w-full bg-white shadow-md z-50">
                <div className="flex justify-end p-4">
                    <button className={`bg-slate-500 text-white py-2 px-10 rounded me-16 ${isFormValid ? 'cursor-pointer opacity-100' : 'cursor-not-allowed opacity-50'}`} disabled={!isFormValid} onClick={handleNextClick}>
                        Save As Draft
                    </button>
                    <button className={`bg-green-600 text-white py-2 px-10 rounded me-36 ${isFormValid ? 'cursor-pointer opacity-100' : 'cursor-not-allowed opacity-50'}`} disabled={!isFormValid} onClick={handleNextClick}>
                        Next
                    </button>
                </div>
            </footer>

            {showModal && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                    <div className="bg-white rounded-lg shadow-lg p-5 w-full max-w-md">
                        <div className="mb-4">
                            <img src={Verified} alt="Verification" className="w-full rounded" />
                        </div>
                        <h2 className="text-xl font-semibold mb-3 text-center">Please be ready with the following for a smooth registration</h2>
                        <hr className="border-t border-gray-300 -mx-6" />
                        <ul className="space-y-3 mt-4">
                            <li className="flex items-center">
                                <FontAwesomeIcon icon={faCheckCircle} className="icons me-3" />
                                <span>PAN card</span>
                            </li>
                            <li className="flex items-center">
                                <FontAwesomeIcon icon={faCheckCircle} className="icons me-3" />
                                <span>FSSAI license <span className="text-gray-500 block text-sm">
                                    Don't have a FSSAI license?
                                </span></span>
                            </li>
                            <li className="flex items-center">
                                <FontAwesomeIcon icon={faCheckCircle} className="icons me-3" />
                                <span>Bank account details</span>
                            </li>
                            <li className="flex items-center">
                                <FontAwesomeIcon icon={faCheckCircle} className="icons me-3" />
                                <span>Menu details and one dish image</span>
                            </li>
                            <li className="flex items-center">
                                <FontAwesomeIcon icon={faCheckCircle} className="icons me-3" />
                                <span>GST number, if applicable <span className="text-gray-500 block text-sm">Not GST registered?
                                </span>
                                </span>
                            </li>
                        </ul>
                        <hr className="border-t border-gray-300 mt-5 -mx-5" />
                        <div className="flex justify-center mt-3">
                            <button onClick={toggleModal} className="okay-btn text-white py-2 px-6 rounded-lg">Okay</button>
                        </div>
                    </div>
                </div>
            )}

            {showReferModal && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                    <div className="bg-white rounded-lg shadow-lg p-3 w-full max-w-sm">
                        <div className="flex justify-between items-center mb-4">
                            <h2 className="text-xl font-semibold">Email ID of referrer</h2>
                            <button onClick={toggleReferModal} className="text-gray-400 hover:text-gray-600">
                                <FontAwesomeIcon icon={faTimes} className="text-xl" />
                            </button>
                        </div>
                        <hr className="-mx-3 border-gray-300 mb-4" />
                        <div className="mb-4">
                            <input type="email" className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-1 focus:ring-lime-500" placeholder="Email id" />
                        </div>
                        <hr className="-mx-3 border-gray-300 mb-4" />
                        <div className="flex justify-center">
                            <button onClick={toggleReferModal} className="okay-btn text-white py-2 px-6 rounded-lg">Okay</button>
                        </div>
                    </div>
                </div>
            )}

            {showLogoutModal && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                    <div className="bg-white rounded-lg shadow-lg p-5 w-full max-w-md">
                        <h2 className="text-xl font-semibold mb-3 text-center">Are you sure you want to log out?</h2>
                        <div className="flex justify-center space-x-4 mt-5">
                            <button
                                className="bg-red-500 text-white py-2 px-6 rounded-lg"
                                onClick={confirmLogout}
                            >
                                Yes, Log Out
                            </button>
                            <button
                                className="bg-gray-300 text-black py-2 px-6 rounded-lg"
                                onClick={() => setShowLogoutModal(false)}
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default RestaurantDocuments;
