import { logout } from './Api'; 

export const logoutService = async (logoutType) => {
  const payload = { logoutType };

  try {
    // Call logout API with the payload
    const response = await logout(payload);
    console.log(response.message);

    // Clear sessionStorage and localStorage
    sessionStorage.clear();
    localStorage.clear();
    window.location.href = '/';
  } catch (error) {
    console.error('Error during logout:', error);

    // Clear storage and navigate even if there is an error
    sessionStorage.clear();
    localStorage.clear();
    window.location.href = '/';
  }
};
