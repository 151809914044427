
import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { verifyOtp, sendOtp } from '../services/AuthApi';
import { handleError } from '../services/errorHandler';
import { useNavigate } from 'react-router-dom';

const Otp = ({ onClose, onTimerEnd }) => {
    const [otp, setOtp] = useState(Array(6).fill(''));
    const inputRefs = useRef([]);
    const navigate = useNavigate();

    const handleOtpChange = (index, value) => {
        if (/^\d*$/.test(value)) {
            const newOtp = [...otp];
            newOtp[index] = value;
            setOtp(newOtp);

            // Move focus to the next input field if not the last one
            if (value !== '' && index < 5) {
                inputRefs.current[index + 1]?.focus();
            }
        }
    };

    const handleKeyDown = (index, event) => {
        if (event.key === 'Backspace') {
            const newOtp = [...otp];
            newOtp[index] = '';
            setOtp(newOtp);

            if (index > 0) {
                inputRefs.current[index - 1]?.focus();
            }
        }
    };

    const handleVerifyOtp = async () => {
        const otpString = otp.join('');
        if (otpString.length === 6) {
            try {
                const phoneNumber = localStorage.getItem("phoneNumber");
                console.log("PhoneNumber", phoneNumber);
                console.log("Sending OTP Verification Request with Payload:", {
                    phoneNumber,
                    otp: otpString,
                    deviceType: 'React_Onboarding_Merchant',
                    appVersion: '1.0.1'
                });
    
                const response = await verifyOtp(phoneNumber, otpString);
                console.log('OTP Verified:', response);
    
                const { accessToken, refreshToken, accessTokenExpiry, refreshTokenExpiry, restaurants, endpoint } = response;
    
                // Store tokens and restaurant ID in localStorage
                localStorage.setItem("accessToken", accessToken);
                localStorage.setItem("refreshToken", refreshToken);
                localStorage.setItem("accessTokenExpiry",accessTokenExpiry);
                localStorage.setItem("refreshTokenExpiry",refreshTokenExpiry);
                localStorage.setItem("endpoint",endpoint);
                localStorage.setItem("restaurant_Id", restaurants);
                const resid = localStorage.getItem("restaurant_Id", restaurants);
                console.log("Restaurant_Id Found", resid );
                
                // Close OTP modal before navigating
                onClose(false);

                // Navigate to the next step
                console.log("Navigating to /register");
                navigate('/register'); // Ensure this line is called without delay
    
            } catch (error) {
                console.error("Error in OTP Verification:", error);
                handleError(error);
            }
        } else {
            alert('Please enter the full 6-digit OTP');
        }
    };

    const handleResendOtp = async () => {
        try {
            const phoneNumber = localStorage.getItem("phoneNumber");
            console.log("Resending OTP to:", phoneNumber);

            const response = await sendOtp(phoneNumber);
            console.log('OTP Resent:', response);

            // Optionally, reset OTP input fields
            setOtp(Array(6).fill(''));
            inputRefs.current[0]?.focus();

        } catch (error) {
            console.error("Error in Resending OTP:", error);
            handleError(error);
        }
    };

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-950 bg-opacity-75 z-50">
            <div className="bg-white rounded-lg shadow-lg w-96 p-6">
                <div className="flex justify-between items-center mb-4">
                    <h2 className="text-xl font-semibold">OTP Verification</h2>
                    <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
                        <FontAwesomeIcon icon={faTimes} />
                    </button>
                </div>
                <div className="text-center">
                    <p className="text-gray-500">Check text messages for your OTP</p>
                    <div className="flex justify-center mt-4 space-x-2">
                        {otp.map((digit, index) => (
                            <input
                                key={index}
                                type="text"
                                maxLength="1"
                                className="w-10 h-10 border rounded text-center shadow-md focus:outline-none focus:ring-2 focus:ring-red-500"
                                value={digit}
                                onChange={(e) => handleOtpChange(index, e.target.value)}
                                onKeyDown={(e) => handleKeyDown(index, e)}
                                ref={(el) => inputRefs.current[index] = el}
                            />
                        ))}
                    </div>
                    <div className="mt-4">
                        <button
                            className="bg-red-500 hover:bg-red-600 text-white font-semibold py-2 px-4 rounded"
                            onClick={handleVerifyOtp}
                        >
                            Verify OTP
                        </button>
                    </div>
                    <div className="mt-2 text-gray-500">
                        Not received OTP? <a href="#" className="text-red-500 hover:underline" onClick={handleResendOtp}>Resend Now</a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Otp;
