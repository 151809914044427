import './App.css';
import Home from './components/Home';
import Registration from './components/Registration';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import RestaurantDocuments from './components/RestaurantDocuments';
import MenuOperational from './components/MenuOperational';
import RestaurantInformation from './components/RestaurantInformation';
import Contract from './components/Contract';
import SignDocument from './components/SignDocument';
import CompleteSign from './components/CompleteSign';

function App() {

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />}> </Route>
        <Route path="/register" element={<Registration />}> </Route>
        <Route path="/menu-operational" element={<MenuOperational />}> </Route>
        <Route path="/restaurant-documents" element={<RestaurantDocuments/>}> </Route>
        <Route path='/restaurant-information' element={<RestaurantInformation/>}> </Route>
        <Route path="/contract" element={<Contract />}> </Route>
        <Route path="/sign-document" element={<SignDocument />}></Route>
        <Route path="/complete-sign" element={<CompleteSign/>}></Route>
      </Routes>
    </Router>  
  );
}

export default App;
