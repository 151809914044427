
import React, { useState } from 'react';
import Logo from '../images/logo.webp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { logoutService } from '../services/LogoutService';

function SignDocument() {
    const navigate = useNavigate();

    const [showLogoutModal, setShowLogoutModal] = useState(false);
    const [showOtpModal, setShowOtpModal] = useState(false);  // State to control OTP modal visibility
    const [isChecked, setIsChecked] = useState(false);
    const [otp, setOtp] = useState(new Array(6).fill("")); // State to store OTP input
    const handleLogoutClick = () => setShowLogoutModal(true);

    const confirmLogout = () => {
        setShowLogoutModal(false);
        logoutService('Session');
    };

    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
    };

    const handleSendOtpClick = () => {
        setShowOtpModal(true);
    };

    const handleOtpChange = (element, index) => {
        if (/^\d*$/.test(element.value)) {
            let newOtp = [...otp];
            newOtp[index] = element.value;
            setOtp(newOtp);

            // Move focus to the next input box
            if (element.nextSibling && element.value) {
                element.nextSibling.focus();
            }
        }
    };

    const handleSignClick = () => {
        setShowOtpModal(false);
        navigate('/complete-sign'); // Replace '/complete-sign' with your actual route
    };

    return (


        <div className="bg-gray-100 min-h-screen flex flex-col"> {/* Ensure full height and background */}
            <header className="w-full bg-white shadow-md flex items-center justify-between p-3">
                <div className="flex items-center">
                    <img src={Logo} alt="Logo" className="h-12" />
                </div>
                <div className="flex items-center">
                    <button
                        className="bg-green-600 text-white py-2 px-10 rounded cursor-pointer"
                        onClick={handleLogoutClick}
                    >
                        LogOut
                    </button>
                </div>
            </header>

            <main className="flex-grow flex flex-col items-center mt-5 w-full">
                <div className=" shadow-lg rounded-lg overflow-hidden flex flex-col items-center">

                    {/* Display the PDF with full width */}
                    <div className="w-full">
                        <iframe
                            src={'https://s3.ap-south-1.amazonaws.com/merchant-assets.thegenie.in/restaurant/MerchantAgreements/dummyRestaurant/The+Genie+Merchant+Onboarding+Documents.pdf'}
                            title="Document"
                            className="w-full h-screen border-0"
                        ></iframe>
                    </div>

                    <div className="w-full p-6 flex flex-col items-center bg-gray-100"> {/* Add background color */}
                        <div className="checkbox-container flex items-center mt-4">
                            <input
                                type="checkbox"
                                id="terms"
                                name="terms"
                                className="mr-2"
                                checked={isChecked}
                                onChange={handleCheckboxChange}
                            />
                            <label htmlFor="terms" className="text-sm">
                                I have read and understood the terms stipulated in the merchant agreement. I consent to the terms outlined.
                            </label>
                        </div>

                        <button
                            onClick={handleSendOtpClick}
                            disabled={!isChecked}  // Disable button if checkbox is not checked
                            className={`text-white text-center rounded h-10 w-44 mt-5 ${isChecked ? 'bg-green-600 cursor-pointer' : 'bg-gray-400 cursor-not-allowed'}`}
                        >
                            Proceed to Sign
                        </button>
                    </div>
                </div>
            </main>

            {/* OTP Modal code here */}
            {showOtpModal && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                    <div className="bg-white rounded-lg shadow-lg p-5 w-full max-w-sm">
                        <div className="flex justify-between items-center mb-4">
                            <h2 className="text-xl font-semibold">Enter verification code</h2>
                            <button onClick={() => setShowOtpModal(false)} className="text-gray-400 hover:text-gray-600">
                                <FontAwesomeIcon icon={faTimes} className="text-xl" />
                            </button>
                        </div>
                        <p className="text-center text-gray-600 mb-4">6 digit OTP has been sent to your registered mobile number</p>
                        <div className="flex justify-center space-x-2 mb-4">
                            {otp.map((data, index) => (
                                <input
                                    key={index}
                                    type="text"
                                    maxLength="1"
                                    className="w-10 h-10 text-center border border-gray-300 rounded-lg"
                                    value={data}
                                    onChange={(e) => handleOtpChange(e.target, index)}
                                />
                            ))}
                        </div>
                        <div className="flex justify-center mt-4">
                            <button
                                onClick={handleSignClick}
                                className="text-white bg-green-600 text-center rounded h-10 w-36"
                            >
                                Sign
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {showLogoutModal && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                    <div className="bg-white rounded-lg shadow-lg p-5 w-full max-w-md">
                        <h2 className="text-xl font-semibold mb-3 text-center">Are you sure you want to log out?</h2>
                        <div className="flex justify-center space-x-4 mt-5">
                            <button
                                className="bg-red-500 text-white py-2 px-6 rounded-lg"
                                onClick={confirmLogout}
                            >
                                Yes, Log Out
                            </button>
                            <button
                                className="bg-gray-300 text-black py-2 px-6 rounded-lg"
                                onClick={() => setShowLogoutModal(false)}
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            )}

        </div>
    );
}

export default SignDocument;
