import React from 'react';
import restaurent from '../images/profile.jpg';

const RestaurentProfileImages = ({ isOpen, onClose }) => {
    if (!isOpen) return null;
  return (
    <div>
        <div className="fixed inset-0 flex items-center justify-center z-50">
                <div className="fixed inset-0" onClick={onClose}></div>
                <div className="bg-white rounded-lg overflow-hidden shadow-xl max-w-lg w-full">
                    <div className="p-4 border-b">
                        <h2 className="text-lg font-bold">Image upload guidelines</h2>
                        <button className="float-right cursor-pointer text-2xl" onClick={onClose}>✖</button>
                    </div>
                    <div className="p-4">
                        <ul className="space-y-4">
                            <li className="flex items-center space-x-2">
                                <img src={restaurent} alt="Guideline 1" className="w-32 h-24 object-cover" />
                                <span>Make sure all details <br /> are visible.</span>
                            </li>
                            <li className="flex items-center space-x-2">
                                <img src={restaurent} alt="Guideline 2" className="w-32 h-24 object-cover" />
                                <span>Image should not be blurry.</span>
                            </li>
                            <li className="flex items-center space-x-2">
                                <img src={restaurent} alt="Guideline 3" className="w-32 h-24 object-cover" />
                                <span>Image should not be cropped.</span>
                            </li>
                        </ul>
                    </div>
                    <div className="p-4 border-t">
                        <button className="bg-green-500 text-white px-4 py-2 rounded" onClick={onClose}>Upload now</button>
                    </div>
                </div>
            </div>
    </div>
  )
}

export default RestaurentProfileImages
