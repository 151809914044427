import React, { useState } from 'react';
import './Home.css';
import Logo from '../images/logo.webp'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import Login from './Login';
import { Link } from 'react-router-dom';

const Home = () => {
    const [isLoginModalOpen, setLoginModalOpen] = useState(false);
    return (
        <div className='home-container h-screen flex flex-col justify-center items-center'>
            <div className='home flex flex-col w-full'>
                {/* Header section */}
                <div className='header flex justify-between items-center text-white mx-36 py-4'>
                    <img src={Logo} alt='Logo' className='logo' />
                </div>
                {/* Main content section */}
                <div className='flex justify-center items-center flex-grow'>
                    <div className='content text-center'>
                        <h1 className='text-white text-4xl font-bold mb-1'>Partner with Genie</h1>
                        <p className='text-white text-4xl font-bold mb-4 whitespace-nowrap'>at 0% commission for the 1st month!</p>
                        <p className='text-white text-base'>And get ads worth INR 1500. Valid for new restaurant partners in select.</p>
                        <div className='flex justify-center mt-2 space-x-4'>
                            <button className='register-btn cursor-pointer text-white text-base py-2 px-20 rounded bg-green-600 whitespace-nowrap' onClick={() => setLoginModalOpen(true)}>Register your restaurant</button>
                            <Link to="https://merchant.thegenie.in/login" className='bg-white hover:bg-gray-200 cursor-pointer text-black text-base py-2 px-6 rounded whitespace-nowrap'>Login to view your existing restaurants</Link>
                        </div>
                        <p className='text-white text-base mt-2'>Need help? Contact: <a href='tel:+91 9080777754' className='phoneNum'>+91 90-80-77-77-54</a></p>
                    </div>
                </div>
                {/* Additional Content Section */}
                <div className=' mb-20 bg-white text-black p-6 rounded shadow-lg max-w-2xl mx-auto'>
                    <h2 className='text-2xl font-bold mb-1 text-center'>Get started with online ordering</h2>
                    <p className='text-base text-gray-500 text-center mb-4'>Please keep the documents ready for smooth signup</p>
                    <div className='grid grid-cols-2 gap-4'>
                        <ul className='space-y-5'>
                            <li className='flex items-center'><FontAwesomeIcon icon={faCheckCircle} className='doc-icon mr-2' />FSSAI license copy</li>
                            <li className='flex items-center'><FontAwesomeIcon icon={faCheckCircle} className='doc-icon mr-2' />Regular GSTIN</li>
                            <li className='flex items-center'><FontAwesomeIcon icon={faCheckCircle} className='doc-icon mr-2' />Your restaurant menu</li>
                        </ul>
                        <ul className='space-y-5'>
                            <li className='flex items-center'><FontAwesomeIcon icon={faCheckCircle} className='doc-icon mr-2' />PAN card copy</li>
                            <li className='flex items-center'><FontAwesomeIcon icon={faCheckCircle} className='doc-icon mr-2' />Bank account details</li>
                            <li className='flex items-center'><FontAwesomeIcon icon={faCheckCircle} className='doc-icon mr-2' />Dish images for top 5 items</li>
                        </ul>
                    </div>
                </div>
                {/* Login Modal */}
                <Login isOpen={isLoginModalOpen} onClose={() => setLoginModalOpen(false)} />
            </div>
        </div>


    );
}

export default Home;



