
import React, { useState, useEffect } from 'react';
import {
    confirmDeliveryMenuImageUpload,
    confirmFoodImageUpload,
    confirmProfileImageUpload,
    confirmStoreImageUpload,
    fetchActiveSearchCategories,
    uploadDeliveryMenuImage,
    uploadFoodImage,
    uploadProfileImage,
    uploadStoreImage,
    fetchDeliveryMenuImages,
    fetchFoodImages,
    fetchProfileImage,
    fetchStoreImages,
    deleteDeliveryMenuImage,
    deleteFoodImage,
    deleteStoreImage,
    deleteProfileImage
} from '../services/Api'; // Import the API functions
import Logo from '../images/logo.webp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useNavigate } from 'react-router-dom';
import './Registration.css';
import { faFileAlt, faPlay, faUpload, faChevronDown, faPlus } from '@fortawesome/free-solid-svg-icons';
import Verified from '../images/verified.webp';
import RestaurantImages from './RestaurantImages';
import FoodImages from './FoodImages';
import DeliveryMenu from './DeliveryMenu';
import RestaurentProfileImages from './RestaurentProfileImages';
import imageCompression from 'browser-image-compression'; // Import image compression library
import example from "../images/restaurantt.png";
import { logoutService } from "../services/LogoutService";

const MenuOperational = () => {
    const [showModal, setShowModal] = useState(false);
    const [showReferModal, setShowReferModal] = useState(false);
    const [showLogoutConfirm, setShowLogoutConfirm] = useState(false);
    // const [isSameAsOwner, setIsSameAsOwner] = useState(false);
    const [isRestaurentModalOpen, setRestaurentModelOpen] = useState(false);
    const [isFoodModalOpen, setFoodModalOpen] = useState(false);
    const [isDelieveryMenuOpen, setDelieveryMenuOpen] = useState(false);
    const [isProfileImageOpen, setProfileImageOpen] = useState(false);
    const [restaurantImages, setRestaurantImages] = useState([]);
    const [foodImages, setFoodImages] = useState([]);
    const [deliveryMenuImages, setDeliveryMenuImages] = useState([]);
    const [profileImage, setProfileImage] = useState(null);
    const [showAll, setShowAll] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedCuisines, setSelectedCuisines] = useState([]);
    const [cuisines, setCuisines] = useState([]);
    const [showLogoutOptions, setShowLogoutOptions] = useState(false);
    const [showLogoutModal, setShowLogoutModal] = useState();
    const navigate = useNavigate();

    const [days, setDays] = useState({
        Monday: { slots: [{ open: 9, close: 21 }], enabled: false },
        Tuesday: { slots: [{ open: 9, close: 21 }], enabled: false },
        Wednesday: { slots: [{ open: 9, close: 21 }], enabled: false },
        Thursday: { slots: [{ open: 9, close: 21 }], enabled: false },
        Friday: { slots: [{ open: 9, close: 21 }], enabled: false },
        Saturday: { slots: [{ open: 9, close: 21 }], enabled: false },
        Sunday: { slots: [{ open: 9, close: 21 }], enabled: false },
    });

    const [diningHoursSameAsDelivery, setDiningHoursSameAsDelivery] = useState(false);
    const [diningDays, setDiningDays] = useState({ ...days });

    const today = new Date().toLocaleString('en-US', { weekday: 'long' });

    useEffect(() => {
        setDays(prevDays => ({
            ...prevDays,
            [today]: { ...prevDays[today], enabled: true }
        }));
    }, [today]);

    const fetchCategoriesWithToken = async () => {
        try {
            try {
                const categories = await fetchActiveSearchCategories();
                setCuisines(categories);
            } catch (error) {
                if (error.response && error.response.status === 401) {
                    // Token expired, refresh token
                    const categories = await fetchActiveSearchCategories();
                    setCuisines(categories);
                } else {
                    console.error('Error fetching categories:', error);
                }
            }
        } catch (error) {
            console.error('Error handling tokens or fetching categories:', error);
        }
    };

    useEffect(() => {
        const token = localStorage.getItem('accessToken');
        if (!token) {
            navigate('/');
        }
    }, [navigate]);

    useEffect(() => {
        fetchCategoriesWithToken();
        fetchAllImages(); // Fetch all images on component mount
    }, []);

    const fetchAllImages = async () => {
        try {
            const [restaurant, food, deliveryMenu, profile] = await Promise.all([
                fetchStoreImages(),
                fetchFoodImages(),
                fetchDeliveryMenuImages(),
                fetchProfileImage(),
            ]);

            setRestaurantImages(restaurant);
            setFoodImages(food);
            setDeliveryMenuImages(deliveryMenu);
            setProfileImage(profile);

            // Check if any of the image arrays are empty and display an alert
            if (restaurant.length === 0 && food.length === 0 && deliveryMenu.length === 0 && !profile) {
                alert('No images to show. Please upload the images.');
            }
        } catch (error) {
            console.error('Error fetching images:', error);
            alert('Failed to fetch images. Please try again.');
        }
    };

    const handleImageUpload = async (e, uploadFunction, confirmUploadFunction, setImages) => {
        const files = Array.from(e.target.files);
        const validFiles = files.filter(file => {
            const validTypes = ['image/jpeg', 'image/png', 'image/jpg'];
            const validSize = 5 * 1024 * 1024; // 5MB
            return validTypes.includes(file.type) && file.size <= validSize;
        });

        if (validFiles.length !== files.length) {
            alert("Some files were not added due to invalid type or size.");
        }

        for (const file of validFiles) {
            try {
                const options = {
                    maxSizeMB: 1,
                    maxWidthOrHeight: 1024,
                    fileType: 'image/webp',
                };

                const webpFile = await imageCompression(file, options);
                const { presignedURL, metadataId } = await uploadFunction('webp');

                await fetch(presignedURL, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'image/webp',
                    },
                    body: webpFile,
                });

                const restaurantId = localStorage.getItem("restaurant_Id");

                await confirmUploadFunction(metadataId, true, restaurantId);
                setImages(prevImages => [...prevImages, URL.createObjectURL(webpFile)]);
            } catch (error) {
                console.error('Error uploading file:', error);
                alert('Failed to upload the image');
            }
        }
    };

    const handleRestaurantImageChange = (e) => {
        handleImageUpload(e, uploadStoreImage, confirmStoreImageUpload, setRestaurantImages);
    };

    const handleFoodImageChange = (e) => {
        handleImageUpload(e, uploadFoodImage, confirmFoodImageUpload, setFoodImages);
    };

    const handleDeliveryMenuImageChange = (e) => {
        handleImageUpload(e, uploadDeliveryMenuImage, confirmDeliveryMenuImageUpload, setDeliveryMenuImages);
    };

    const handleProfileImageChange = (e) => {
        const file = e.target.files[0];
        if (!file) return;

        const validTypes = ['image/jpeg', 'image/png', 'image/jpg'];
        const validSize = 5 * 1024 * 1024; // 5MB

        if (!validTypes.includes(file.type) || file.size > validSize) {
            alert("Invalid file type or size.");
            return;
        }

        handleImageUpload(e, uploadProfileImage, confirmProfileImageUpload, setProfileImage);
    };

    const handleDayChange = (day) => {
        setDays(prevDays => ({
            ...prevDays,
            [day]: { ...prevDays[day], enabled: !prevDays[day].enabled }
        }));
    };

    const handleTimeChange = (day, index, type, value) => {
        setDays(prevDays => {
            const newSlots = [...prevDays[day].slots];
            newSlots[index][type] = value;
            return {
                ...prevDays,
                [day]: { ...prevDays[day], slots: newSlots }
            };
        });
    };

    const addTimeSlot = (day) => {
        setDays(prevDays => ({
            ...prevDays,
            [day]: { ...prevDays[day], slots: [...prevDays[day].slots, { open: 9, close: 21 }] }
        }));
    };

    const handleLogoutClick = (type) => {
        setShowLogoutOptions(false);
        setShowLogoutModal(false);
        logoutService('Session');
    };

    const handleCuisineClick = (cuisine) => {
        const alreadySelected = selectedCuisines.includes(cuisine);
        if (alreadySelected) {
            setSelectedCuisines(selectedCuisines.filter(item => item !== cuisine));
        } else {
            if (selectedCuisines.length < 10) {
                setSelectedCuisines([...selectedCuisines, cuisine]);
            } else {
                alert('You can only select up to 10 cuisines');
            }
        }
    };

    const validateForm = () => {
        return (
            restaurantImages.length > 0 &&
            profileImage &&
            selectedCuisines.length > 0 &&  // Ensure at least one cuisine is selected
            selectedCuisines.length <= 10 &&  // Ensure no more than 10 cuisines are selected
            Object.values(days).some(day => day.enabled) &&
            Object.values(days).every(day => day.slots.every(slot => slot.open && slot.close)) &&
            Object.values(diningDays).every(day => day.slots.every(slot => slot.open && slot.close))
        );
    };

    const filteredCuisines = cuisines.filter(cuisine =>
        cuisine.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const toggleModal = () => setShowModal(!showModal);
    const toggleReferModal = () => setShowReferModal(!showReferModal);
    // const handleCheckboxChange = () => setIsSameAsOwner(!isSameAsOwner);

    const handleDiningHoursCheckboxChange = () => {
        const newValue = !diningHoursSameAsDelivery;
        setDiningHoursSameAsDelivery(newValue);

        if (newValue) {
            setDiningDays({ ...days });
        } else {
            setDiningDays({
                Monday: { slots: [{ open: 9, close: 21 }], enabled: true },
                Tuesday: { slots: [{ open: 9, close: 21 }], enabled: true },
                Wednesday: { slots: [{ open: 9, close: 21 }], enabled: true },
                Thursday: { slots: [{ open: 9, close: 21 }], enabled: true },
                Friday: { slots: [{ open: 9, close: 21 }], enabled: true },
                Saturday: { slots: [{ open: 9, close: 21 }], enabled: true },
                Sunday: { slots: [{ open: 9, close: 21 }], enabled: true },
            });
        }
    };

    const handleDiningTimeChange = (day, index, type, hour) => {
        setDiningDays((prevDiningDays) => {
            const newSlots = [...prevDiningDays[day].slots];
            newSlots[index][type] = hour;
            return {
                ...prevDiningDays,
                [day]: { ...prevDiningDays[day], slots: newSlots }
            };
        });
    };

    const handleDiningDayChange = (day) => {
        setDiningDays((prevDiningDays) => ({
            ...prevDiningDays,
            [day]: { ...prevDiningDays[day], enabled: !prevDiningDays[day].enabled }
        }));
    };

    const restaurantId = localStorage.getItem("restaurant_Id");

    const removeRestaurantImage = async (index, imageId) => {
        try {
            await deleteStoreImage(imageId, restaurantId);
            setRestaurantImages(restaurantImages.filter((_, i) => i !== index));
        } catch (error) {
            console.error('Error deleting restaurant image:', error);
        }
    };

    const removeFoodImage = async (index, imageId) => {
        try {
            await deleteFoodImage(imageId, restaurantId);
            setFoodImages(foodImages.filter((_, i) => i !== index));
        } catch (error) {
            console.error('Error deleting food image:', error);
        }
    };

    const removeDeliveryMenuImage = async (index, imageId) => {
        try {
            await deleteDeliveryMenuImage(imageId, restaurantId);
            setDeliveryMenuImages(deliveryMenuImages.filter((_, i) => i !== index));
        } catch (error) {
            console.error('Error deleting delivery menu image:', error);
        }
    };


    const handleNextClick = () => {
        if (validateForm()) {
            navigate('/restaurant-documents');
        } else {
            alert('Please complete all required fields.');
        }
    };

    const [selectedDevice, setSelectedDevice] = useState(null);

    const handleDeviceSelect = (device) => {
        setSelectedDevice(device);
    };

    return (
        <div className='registration bg-gray-100 h-screen flex'>

            <header className="fixed top-0 w-full bg-white shadow-md z-50 flex items-center justify-between">
                <div className="flex items-center ms-4 p-3">
                    <img src={Logo} alt="Logo" className="h-12" />
                </div>
                <div className="flex items-center me-4">
                    <button
                        className="bg-green-600 text-white py-2 px-10 rounded cursor-pointer"
                        onClick={() => setShowLogoutConfirm(true)}
                    >
                        LogOut
                    </button>
                </div>
            </header>

            <main className='pt-20 pb-20 flex w-full'>

                <div className='sidebar w-1/4 bg-gray-100 overflow-y-auto h-full p-6'>
                    <div className="steps bg-white shadow-lg rounded-lg px-5 py-3 mb-6">
                        <h2 className="text-xl text-gray-600 font-semibold mb-3">Complete your registration</h2>
                        <hr className="-mx-5 border-gray-300" />
                        <ul className="mt-5 space-y-7">
                            <li className="flex items-center space-x-3">
                                <div className="text-2xl">&#127828;</div>
                                <div>
                                    <Link to="/register" className="doc-content"> Restaurant Information</Link>
                                    <p className="text-sm font-bold text-gray-500">Name, location and contact number</p>
                                </div>
                            </li>
                            <li className="flex items-center space-x-3">
                                <div className="text-2xl">&#128221;</div>
                                <div>
                                    <Link to="/menu-operational" className="doc-content"> Menu and Operational Details</Link>
                                </div>
                            </li>
                            <li className="flex items-center space-x-3">
                                <div className="text-2xl">&#128194;</div>
                                <div>
                                    <Link to="/restaurant-documents" className="doc-content"> Restaurant documents</Link>
                                </div>
                            </li>
                            <li className="flex items-center space-x-3">
                                <div className="text-2xl">&#128196;</div>
                                <div>
                                    <Link to="/contract" className='doc-content'>Partner Contract</Link>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div className="docNotification flex justify-between items-center border p-3 rounded-lg cursor-pointer" onClick={toggleModal}>
                        <FontAwesomeIcon icon={faFileAlt} className='icons text-xl' />
                        <span className='text-black font-semibold me-8'>Documents required for registration</span>
                        <div>
                            <FontAwesomeIcon icon={faPlay} className='icons text-base' />
                        </div>
                    </div>
                    <div className="refer flex justify-between items-center border p-3 rounded-lg mt-6 cursor-pointer" onClick={toggleReferModal}>
                        <p className='text-black font-semibold'>Did someone refer you to this platform?</p>
                        <p className='yes font-semibold'>Yes</p>
                    </div>
                </div>

                <div className="content flex-1 overflow-y-auto p-6 bg-gray-100">
                    <h1 className="text-3xl font-bold mb-4">Menu and other operation details</h1>
                    <div className="bg-white shadow-lg rounded-lg p-5 w-full ">
                        <h2 className="text-xl font-bold">Add restaurant images</h2>
                        <p className="text-sm text-gray-400 mb-4">Upload at least one entrance image of your restaurant along with interior images, for your Genie page</p>
                        <div className="mb-2">
                            <input
                                type="file"
                                multiple
                                accept="image/jpeg, image/png, image/jpg"
                                onChange={handleRestaurantImageChange}
                                className="hidden"
                                id="restaurant-image-upload"
                            />
                            <label htmlFor="restaurant-image-upload" className="cursor-pointer">
                                <div className="upload-images-border border-dashed border-2 rounded-lg p-6 flex flex-col items-center justify-center text-gray-600">
                                    {/* <FontAwesomeIcon icon={faChevronDown} className="camera-icon w-8 h-8 mb-1" /> */}

                                    <FontAwesomeIcon icon={faPlus} className="camera-icon w-8 h-8 mb-1" />

                                    <span className='add-images-btn font-semibold'>Add more images</span>
                                    <p className="text-sm">jpeg, png or jpg formats up to 50MB</p>
                                </div>
                            </label>
                        </div>
                        <div className="flex flex-wrap justify-center">
                            {restaurantImages.map((image, index) => (
                                <div key={index} className="relative m-2">
                                    <img
                                        src={image || example}
                                        alt={`restaurant-${index}`}
                                        className="w-24 h-24 object-cover rounded"
                                    />
                                    <div className="absolute top-0 left-0 bg-gray-700 text-white text-xs w-6 h-6 flex items-center justify-center rounded-full">
                                        {index + 1}
                                    </div>
                                    <button
                                        className="absolute top-0 right-0 bg-red-600 text-white rounded-full w-6 h-6 flex items-center justify-center"
                                        onClick={() => removeRestaurantImage(index, image.id)}
                                    >
                                        &times;
                                    </button>
                                </div>
                            ))}
                        </div>

                        <div className="mt-4 text-center">
                            <button onClick={() => setRestaurentModelOpen(true)} className="text-green-500 underline">Guidelines to upload restaurant images</button>
                        </div>
                    </div>

                    <RestaurantImages isOpen={isRestaurentModalOpen} onClose={() => setRestaurentModelOpen(false)} />

                    {/* Food images */}
                    <div className="bg-white shadow-lg rounded-lg p-5 mt-4 w-full">
                        <h2 className="text-xl font-bold">Add food images (optional)</h2>
                        <p className="text-sm text-gray-400 mb-4">These images will be shown on your restaurant's Genie dining page.</p>
                        <div className="mb-2">
                            <input
                                type="file"
                                multiple
                                accept="image/jpeg, image/png, image/jpg"
                                onChange={handleFoodImageChange}
                                className="hidden"
                                id="food-image-upload"
                            />
                            <label htmlFor="food-image-upload" className="cursor-pointer">
                                <div className="upload-images-border border-dashed border-2 rounded-lg p-6 flex flex-col items-center justify-center text-gray-600">
                                    <FontAwesomeIcon icon={faPlus} className="camera-icon w-8 h-8 mb-1" />

                                    <span className='add-images-btn font-semibold'>Add more images</span>
                                    <p className="text-sm">jpeg, png or jpg formats up to 50MB</p>
                                </div>
                            </label>
                        </div>
                        <div className="flex flex-wrap justify-center">
                            {foodImages.map((image, index) => (
                                <div key={index} className="relative m-2">
                                    <img
                                        src={image || example}
                                        alt={`food-${index}`}
                                        className="w-24 h-24 object-cover rounded"
                                    />
                                    <div className="absolute top-0 left-0 bg-gray-700 text-white text-xs w-6 h-6 flex items-center justify-center rounded-full">
                                        {index + 1}
                                    </div>
                                    <button
                                        className="absolute top-0 right-0 bg-red-600 text-white rounded-full w-6 h-6 flex items-center justify-center"
                                        onClick={() => removeFoodImage(index, image.id)}
                                    >
                                        &times;
                                    </button>
                                </div>
                            ))}
                        </div>
                        <div className="mt-4 text-center">
                            <button onClick={() => setFoodModalOpen(true)} className="text-green-500 underline">Guidelines to upload food images</button>
                        </div>
                    </div>
                    <FoodImages isOpen={isFoodModalOpen} onClose={() => setFoodModalOpen(false)} />

                    {/* Delivery Menu images */}
                    <div className="bg-white shadow-lg rounded-lg p-5 mt-4 w-full">
                        <h2 className="text-xl font-bold">Add delivery menu images</h2>
                        <p className="text-sm text-gray-400 mb-4">These will be used to create your in-app menu for online ordering.</p>
                        <div className="mb-2">
                            <input
                                type="file"
                                multiple
                                accept="image/jpeg, image/png, image/jpg"
                                onChange={handleDeliveryMenuImageChange}
                                className="hidden"
                                id="delivery-menu-image-upload"
                            />
                            <label htmlFor="delivery-menu-image-upload" className="cursor-pointer">
                                <div className="upload-images-border border-dashed border-2 rounded-lg p-6 flex flex-col items-center justify-center text-gray-600">
                                    <FontAwesomeIcon icon={faPlus} className="camera-icon w-8 h-8 mb-1" />

                                    <span className='add-images-btn font-semibold'>Add delivery menu images</span>
                                    <p className="text-sm">jpeg, png or jpg formats up to 50MB</p>
                                </div>
                            </label>
                        </div>
                        <div className="flex flex-wrap justify-center">
                            {deliveryMenuImages.map((image, index) => (
                                <div key={index} className="relative m-2">
                                    <img
                                        src={image || example}
                                        alt={`deliveryMenu-${index}`}
                                        className="w-24 h-24 object-cover rounded"
                                    />
                                    <div className="absolute top-0 left-0 bg-gray-700 text-white text-xs w-6 h-6 flex items-center justify-center rounded-full">
                                        {index + 1}
                                    </div>
                                    <button
                                        className="absolute top-0 right-0 bg-red-600 text-white rounded-full w-6 h-6 flex items-center justify-center"
                                        onClick={() => removeDeliveryMenuImage(index, image.id)}
                                    >
                                        &times;
                                    </button>
                                </div>
                            ))}
                        </div>

                        <div className="mt-4 text-center">
                            <button onClick={() => setDelieveryMenuOpen(true)} className="text-green-500 underline">Guidelines to upload delivery menu images</button>
                        </div>
                    </div>
                    <DeliveryMenu isOpen={isDelieveryMenuOpen} onClose={() => setDelieveryMenuOpen(false)} />

                    {/* Profile Image */}
                    <div className="bg-white shadow-lg rounded-lg p-5 mt-4 w-full">
                        <h1 className="text-xl font-bold">Add restaurant profile image</h1>
                        <p className="text-sm text-gray-400 mb-2">This will be your restaurant's profile picture on Genie – so use your best food shot!</p>
                        <div className="bg-white shadow-lg rounded-lg p-5 w-full flex">
                            <div className="w-full flex flex-col items-center mb-2">
                                {profileImage ? (
                                    <div className="relative w-full h-96">
                                        <img
                                            src={profileImage || example}
                                            alt="restaurant-profile"
                                            className="w-full h-full object-cover rounded border border-gray-400"
                                        />
                                        <label htmlFor="profile-image-upload" className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 text-white font-semibold py-1 px-3 rounded cursor-pointer">
                                            <FontAwesomeIcon icon={faUpload} className="mr-1" />
                                            Re-upload
                                        </label>
                                        <input
                                            type="file"
                                            accept="image/jpeg, image/png, image/jpg"
                                            onChange={handleProfileImageChange}
                                            className="hidden"
                                            id="profile-image-upload"
                                        />
                                        <p className="text-sm font-semibold text-black mt-1 break-all">{profileImage.name}</p>
                                    </div>
                                ) : (
                                    <div className="border-dashed border-2 border-gray-300 rounded-lg p-6 flex flex-col items-center justify-center text-gray-600">
                                        <FontAwesomeIcon icon={faUpload} className="camera-icon w-8 h-8 mb-1" />
                                        <span className='add-images-btn font-semibold'>Add profile image</span>
                                        <p className="text-sm">jpeg, png or jpg formats up to 5MB</p>
                                        <input
                                            type="file"
                                            accept="image/jpeg, image/png, image/jpg"
                                            onChange={handleProfileImageChange}
                                            className="hidden"
                                            id="profile-image-upload"
                                        />
                                        <label htmlFor="profile-image-upload" className="cursor-pointer">
                                            <span className="underline">Upload Image</span>
                                        </label>
                                    </div>
                                )}
                            </div>
                            {!profileImage && (
                                <div className="flex flex-col items-center justify-center  w-full">
                                    <img src={example} alt="Example of profile picture" className="w-72 h-56 object-cover rounded" />
                                </div>
                            )}
                        </div>
                        <div className="mt-4 text-center">
                            <button onClick={() => setProfileImageOpen(true)} className="text-green-500 underline">Guidelines to upload restaurant profile images</button>
                        </div>
                    </div>
                    <RestaurentProfileImages isOpen={isProfileImageOpen} onClose={() => setProfileImageOpen(false)} />



                    {/* Cuisine Selection */}
                    <div className="bg-white shadow-lg rounded-lg mt-4 p-5 w-full">
                        <h1 className="text-xl font-bold">Select up to 3 cuisines</h1>
                        <p className="text-sm text-gray-400 mb-2">Your restaurant will appear in searches for these cuisines</p>
                        <div className="relative mb-4">
                            <FontAwesomeIcon icon={faChevronDown} className="absolute left-3 top-3 text-gray-400" />
                            <input
                                type="text"
                                placeholder="Search for cuisines"
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                                className="w-full pl-10 p-2 border border-gray-300 outline-none rounded"
                            />
                        </div>
                        <div className="grid grid-cols-2 gap-2">
                            {filteredCuisines.slice(0, showAll ? undefined : 6).map((cuisine, index) => (
                                <button
                                    key={index}
                                    onClick={() => handleCuisineClick(cuisine)}
                                    className={`p-2 cuisine-list border rounded text-center ${selectedCuisines.includes(cuisine) ? 'selected-cuisine ' : 'not-selected-cuisine bg-white'}`}
                                >
                                    {cuisine}
                                </button>
                            ))}
                        </div>
                        {!showAll && filteredCuisines.length > 6 && (
                            <button onClick={() => setShowAll(true)} className="show-more-btn underline mt-4 block ml-auto">
                                Show more
                            </button>
                        )}
                    </div>

                    {/* Delivery timing and days */}
                    <div className="bg-white shadow-lg rounded-lg p-5 mt-4 content w-full">
                        <h1 className="text-xl font-bold mb-2">Restaurant delivery hours</h1>
                        <hr className='-mx-5 mb-2' />
                        <div className="flex flex-wrap gap-2 mb-4">
                            {Object.keys(days).map((day) => (
                                <label key={day} className="flex items-center space-x-2 rounded-md border border-green-500 px-1 py-1">
                                    <input
                                        type="checkbox"
                                        checked={days[day].enabled}
                                        onChange={() => handleDayChange(day)}
                                        className="h-4 w-4 border border-green-500 rounded-sm checked:bg-green-500 checked:border-transparent focus:outline-none focus:ring-0 relative"
                                        id={day}
                                    />
                                    <span
                                        className={`cursor-pointer text-md ${days[day].enabled ? 'text-black' : 'bg-white text-gray-700 border-gray-300'}`}
                                        onClick={() => handleDayChange(day)}
                                    >
                                        {day}
                                    </span>
                                </label>
                            ))}
                        </div>
                        {Object.keys(days).map((day) => (
                            days[day].enabled && (
                                <div key={day}>
                                    {days[day].slots.map((slot, index) => (
                                        <div key={index} className="mb-2">
                                            <div className="w-full pr-2">
                                                {index === 0 && (
                                                    <label className="block text-lg text-black-500 mb-1">{day}</label>
                                                )}
                                                <div className='flex justify-between'>
                                                    <div className='w-full'>
                                                        <p className='text-grey text-sm'>Open Time</p>
                                                        <select
                                                            value={slot.open}
                                                            onChange={(e) => handleTimeChange(day, index, 'open', parseInt(e.target.value))}
                                                            className="block w-full p-1 border border-gray-300 rounded-md"
                                                        >
                                                            {[...Array(24)].map((_, i) => (
                                                                <option key={i} value={i}>{i < 12 ? `${i} AM` : `${i - 12} PM`}</option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                    <div className='w-full mx-2'>
                                                        <p className='text-grey text-sm'>Close Time</p>
                                                        <select
                                                            value={slot.close}
                                                            onChange={(e) => handleTimeChange(day, index, 'close', parseInt(e.target.value))}
                                                            className="block w-full p-1 border border-gray-300 rounded-md"
                                                        >
                                                            {[...Array(24)].map((_, i) => (
                                                                <option key={i} value={i}>{i < 12 ? `${i} AM` : `${i - 12} PM`}</option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                    <button onClick={() => addTimeSlot(day)} className="p-1 mb-2 text-white rounded text-center rounded:sm mt-2 bg-green-400 hover:bg-green-500">
                                        Add Additional Slots
                                    </button>
                                </div>
                            )
                        ))}
                    </div>

                    {/* Dining time */}
                    <div className="bg-white shadow-lg rounded-lg p-5 mt-4 w-full">
                        <h1 className="text-xl font-bold mb-2">Restaurant dining hours</h1>
                        <hr className='-mx-5 mb-2' />
                        <div className="mb-4">
                            <label className="flex items-center space-x-2">
                                <input
                                    type="checkbox"
                                    checked={diningHoursSameAsDelivery}
                                    onChange={handleDiningHoursCheckboxChange}
                                    className="h-4 w-4 text-green-500 border-gray-300 rounded focus:ring-green-500"
                                />
                                <span className="text-sm text-gray-700">My dining hours are same as delivery hours</span>
                            </label>
                        </div>
                        {!diningHoursSameAsDelivery && (
                            <>
                                <div className="flex flex-wrap gap-2 mb-4">
                                    {Object.keys(diningDays).map((day) => (
                                        <label key={day} className="flex items-center space-x-2 rounded-md border border-green-500 px-1 py-1">
                                            <input
                                                type="checkbox"
                                                checked={diningDays[day].enabled}
                                                onChange={() => handleDiningDayChange(day)}
                                                className="appearance-none h-4 w-4 border border-green-500 rounded-sm checked:bg-green-500 checked:border-transparent focus:outline-none focus:ring-0 relative"
                                                id={day}
                                            />
                                            <span
                                                className={`cursor-pointer text-sm ${diningDays[day].enabled ? 'text-black' : 'bg-white text-gray-700 border-gray-300'}`}
                                            >
                                                {day}
                                            </span>
                                        </label>
                                    ))}
                                </div>
                                {Object.keys(diningDays).map((day) => (
                                    diningDays[day].enabled && diningDays[day].slots.map((slot, index) => (
                                        <div key={index} className="flex justify-between mb-4">
                                            <div className="w-full pr-2">
                                                <label className="block text-sm text-gray-500 mb-1">{day} Open Time</label>
                                                <select
                                                    value={slot.open}
                                                    onChange={(e) => handleDiningTimeChange(day, index, 'open', parseInt(e.target.value))}
                                                    className="block w-full p-1 border border-gray-300 rounded-md"
                                                >
                                                    {[...Array(24)].map((_, i) => (
                                                        <option key={i} value={i}>{i < 12 ? `${i} AM` : `${i - 12} PM`}</option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className="w-full pl-2">
                                                <label className="block text-sm text-gray-500 mb-1">{day} Close Time</label>
                                                <select
                                                    value={slot.close}
                                                    onChange={(e) => handleDiningTimeChange(day, index, 'close', parseInt(e.target.value))}
                                                    className="block w-full p-1 border border-gray-300 rounded-md"
                                                >
                                                    {[...Array(24)].map((_, i) => (
                                                        <option key={i} value={i}>{i < 12 ? `${i} AM` : `${i - 12} PM`}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>

                                    ))
                                ))}
                            </>
                        )}
                    </div>

                    <div className="bg-white shadow-lg rounded-lg p-5 mt-4 w-full">
                        <h2 className="text-xl font-bold mb-2">Select the device type you will use for order acceptance</h2>
                        <div className="space-y-6">
                            {/* Genie partner mobile app [Android Only] */}
                            <button
                                onClick={() => handleDeviceSelect('GenieApp')}
                                className={`device flex items-center w-full px-4 py-4 border rounded-lg ${selectedDevice === 'GenieApp' ? 'border-green-500' : 'border-gray-500'}`}
                            >
                                <img src="https://via.placeholder.com/40" alt="Genie app" className="w-10 h-10 mr-3" />
                                <span className="text-left text-lg flex-1">Genie partner mobile app [Android Only]</span>
                            </button>

                            {/* Genie web dashboard */}
                            <button
                                onClick={() => handleDeviceSelect('WebDashboard')}
                                className={`device flex items-center w-full px-4 py-4 border rounded-lg ${selectedDevice === 'WebDashboard' ? 'border-green-500' : 'border-gray-500'}`}
                            >
                                <img src="https://via.placeholder.com/40" alt="Genie web dashboard" className="w-10 h-10 mr-3" />
                                <span className="text-left text-lg flex-1">Genie web dashboard</span>
                            </button>
                        </div>
                    </div>

                </div>
            </main>

            <footer className="fixed bottom-0 w-full bg-white shadow-md z-50">
                <div className="flex justify-end p-4">
                    <button
                        className="bg-slate-500 text-white py-2 px-10 rounded cursor-not-allowed opacity-50 me-16"
                        disabled
                    >
                        Save As Draft
                    </button>

                    <button
                        className={`py-2 px-10 rounded me-36 ${validateForm() ? 'bg-green-600 cursor-pointer text-white' : 'bg-slate-500 cursor-not-allowed opacity-50'}`}
                        onClick={handleNextClick}
                        disabled={!validateForm()}
                    >
                        Next
                    </button>
                </div>
            </footer>

            {/* Modal for Logout Confirmation */}
            {showLogoutConfirm && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                    <div className="bg-white rounded-lg shadow-lg p-5 w-full max-w-md">
                        <h2 className="text-xl font-semibold mb-3">Are you sure you want to logout?</h2>
                        <div className="flex justify-end mt-5">
                            <button
                                className="bg-gray-400 text-white py-2 px-6 rounded-lg mr-4"
                                onClick={() => setShowLogoutConfirm(false)}
                            >
                                Cancel
                            </button>
                            <button
                                className="bg-red-600 text-white py-2 px-6 rounded-lg"
                                onClick={() => {
                                    setShowLogoutConfirm(false);
                                    navigate('/'); // Redirect to login page
                                }}
                            >
                                Logout
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {/* Modal for Logout Options */}
            {showLogoutOptions && (
                <div className={`fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50 ${showLogoutModal}`}>
                    <div className="bg-white rounded-lg shadow-lg p-5 w-full max-w-md">
                        <h2 className="text-xl text-center font-semibold mb-3">Select Logout Option</h2>
                        <div className="flex justify-between mt-10">
                            <button
                                className="bg-green-500 text-white text-sm text-center p-3 rounded-lg mr-4"
                                onClick={() => handleLogoutClick('Session')}
                            >
                                Logout from This Device
                            </button>
                            <button
                                className="bg-red-600 text-white text-sm text-center p-3 rounded-lg"
                                onClick={() => handleLogoutClick('Account')}
                            >
                                Logout from All Devices
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {/* Modal for Documents Required */}
            {showModal && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                    <div className="bg-white rounded-lg shadow-lg p-5 w-full max-w-md">
                        <div className="mb-4">
                            <img src={Verified} alt="Verification" className="w-full rounded" />
                        </div>
                        <h2 className="text-xl font-semibold mb-3 text-center">Please be ready with the following for a smooth registration</h2>
                        <hr className="border-t border-gray-300 -mx-6" />
                        <ul className="space-y-3 mt-4">
                            <li className="flex items-center">
                                <FontAwesomeIcon icon={faChevronDown} className="icons me-3" />
                                <span>PAN card</span>
                            </li>
                            <li className="flex items-center">
                                <FontAwesomeIcon icon={faChevronDown} className="icons me-3" />
                                <span>FSSAI license <span className="text-gray-500 block text-sm">Don't have a FSSAI license?
                                </span></span>
                            </li>
                            <li className="flex items-center">
                                <FontAwesomeIcon icon={faChevronDown} className="icons me-3" />
                                <span>Bank account details</span>
                            </li>
                            <li className="flex items-center">
                                <FontAwesomeIcon icon={faChevronDown} className="icons me-3" />
                                <span>Menu details and one dish image</span>
                            </li>
                            <li className="flex items-center">
                                <FontAwesomeIcon icon={faChevronDown} className="icons me-3" />
                                <span>GST number, if applicable <span className="text-gray-500 block text-sm">Not GST registered?
                                </span></span>
                            </li>
                        </ul>
                        <hr className="border-t border-gray-300 mt-5 -mx-5" />
                        <div className="flex justify-center mt-3">
                            <button onClick={toggleModal} className="okay-btn text-white py-2 px-6 rounded-lg">Okay</button>
                        </div>
                    </div>
                </div>
            )}

            {/* Modal for Refer */}
            {showReferModal && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                    <div className="bg-white rounded-lg shadow-lg p-3 w-full max-w-sm">
                        <div className="flex justify-between items-center mb-4">
                            <h2 className="text-xl font-semibold">Email ID of referrer</h2>
                            <button onClick={toggleReferModal} className="text-gray-400 hover:text-gray-600">
                                <FontAwesomeIcon icon={faChevronDown} className="text-xl" />
                            </button>
                        </div>
                        <hr className="-mx-3 border-gray-300 mb-4" />
                        <div className="mb-4">
                            <input type="email" className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-1 focus:ring-lime-500" placeholder="Email id" />
                        </div>
                        <hr className="-mx-3 border-gray-300 mb-4" />
                        <div className="flex justify-center">
                            <button onClick={toggleReferModal} className="okay-btn text-white py-2 px-6 rounded-lg">Okay</button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default MenuOperational;
