import React, { useState } from 'react';

const RestaurantInformation = () => {
    const [restaurantName, setRestaurantName] = useState('');
    const [ownerName, setOwnerName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [primaryPhoneNumber, setPrimaryPhoneNumber] = useState('');
    const [isSameAsOwner, setIsSameAsOwner] = useState(false);
    const [latitude, setLatitude] = useState('');
    const [longitude, setLongitude] = useState('');

    const handlePhoneNumberChange = (e) => setPhoneNumber(e.target.value);
    const handlePrimaryPhoneNumberChange = (e) => setPrimaryPhoneNumber(e.target.value);
    const handleCheckboxChange = () => setIsSameAsOwner(!isSameAsOwner);

    return (
        <div className="content flex flex-col py-3 m-4 content">
            <h1 className="text-3xl font-bold mb-4">Restaurant information</h1>
            <div className="bg-white shadow-lg rounded-lg p-5 w-full">
                <h2 className="text-2xl font-bold ">Restaurant name</h2>
                <p className="text-gray-600 font-medium text-sm mb-2">Customers will see this name on Genie</p>
                <hr className='-mx-6' />
                <form className='mt-3'>
                    <input
                        type="text"
                        value={restaurantName}
                        onChange={(e) => setRestaurantName(e.target.value)}
                        className="mt-2 p-2 w-full rounded-lg border border-gray-300 outline-none shadow-sm sm:text-sm"
                        placeholder="Restaurant name*"
                    />
                </form>
            </div>
            <div className="bg-white shadow-lg rounded-lg p-5 w-full  mt-4">
                <h2 className="text-2xl font-bold">Owner details</h2>
                <p className="text-sm font-medium text-gray-600 mb-2">Genie will use these details for all business communications and updates</p>
                <hr className='-mx-6' />
                <form className='mt-3'>
                    <div className="grid grid-cols-2 gap-4 mb-4">
                        <input
                            type="text"
                            value={ownerName}
                            onChange={(e) => setOwnerName(e.target.value)}
                            className="mt-2 p-2 w-full rounded-md border border-gray-300 outline-none shadow-sm sm:text-sm"
                            placeholder="Full name*"
                        />
                        <input
                            type="email"
                            className="mt-2 p-2 w-full rounded-md border border-gray-300 outline-none shadow-sm sm:text-sm"
                            placeholder="Email address (optional)"
                        />
                    </div>
                    <div className="mb-4">
                        <div className="flex items-center border border-gray-300 outline-none shadow-sm sm:text-sm rounded p-2">
                            <div className="flex items-center">
                                <img src="https://upload.wikimedia.org/wikipedia/commons/b/bc/Flag_of_India.png" alt="India Flag" className="w-6 h-4 mr-2" />
                                <span className="text-gray-700">+91</span>
                            </div>
                            <input
                                type="tel"
                                placeholder="Phone number*"
                                value={phoneNumber}
                                onChange={handlePhoneNumberChange}
                                minLength="10"
                                maxLength="10"
                                className="ml-2 outline-none flex-1 border-l border-gray-300 pl-2"
                                required
                            />
                        </div>
                    </div>
                    <div className="mb-4">
                        <label className="text-lg font-medium">Restaurant's primary contact number</label>
                        <p className="text-sm text-gray-500 mb-2">Customers, delivery partners and Genie may call on this number for order support</p>
                        <div className="flex items-center border border-gray-300 rounded-md mt-2">
                            <input type="checkbox" className="h-4 w-4 text-indigo-600 ml-2 border-gray-300 rounded" checked={isSameAsOwner} onChange={handleCheckboxChange} />
                            <label className="ml-1 w-full p-2 text-sm text-gray-600">Same as owner mobile number</label>
                        </div>
                        {!isSameAsOwner && (
                            <div className="flex items-center border border-gray-300 outline-none shadow-sm sm:text-sm rounded p-2 mt-2">
                                <div className="flex items-center">
                                    <img src="https://upload.wikimedia.org/wikipedia/commons/b/bc/Flag_of_India.png" alt="India Flag" className="w-6 h-4 mr-2" />
                                    <span className="text-gray-700">+91</span>
                                </div>
                                <input
                                    type="tel"
                                    placeholder="Phone number*"
                                    value={primaryPhoneNumber}
                                    onChange={handlePrimaryPhoneNumberChange}
                                    minLength="10"
                                    maxLength="10"
                                    className="ml-2 outline-none flex-1 border-l border-gray-300 pl-2"
                                    required
                                />
                            </div>
                        )}
                    </div>
                </form>
            </div>
            <div className="bg-white shadow-lg rounded-lg px-5 pt-5 w-full mt-4">
                <h2 className="text-2xl font-bold">Restaurant address details</h2>
                <p className="text-sm font-medium text-gray-600 mb-6">Address details are based on the restaurant location mentioned above</p>
                <form>
                    <div className="grid grid-cols-2 gap-4 mb-4">
                        <div>
                            <label className="text-sm text-gray-500">Enter Latitudinal value</label>
                            <input
                                type="text"
                                value={latitude}
                                readOnly
                                className="mt-1 p-2 w-full rounded-md border border-gray-300 outline-none shadow-sm sm:text-sm"
                            />
                        </div>
                        <div>
                            <label className="text-sm text-gray-500">Enter Longitudinal value</label>
                            <input
                                type="text"
                                value={longitude}
                                readOnly
                                className="mt-1 p-2 w-full rounded-md border border-gray-300 outline-none shadow-sm sm:text-sm"
                            />
                        </div>
                    </div>
                    <div className="grid grid-cols-2 gap-4 mb-4">
                        <input
                            type="text"
                            placeholder="Shop no. / building no. (optional)"
                            className="mt-2 p-2 w-full rounded-md border border-gray-300 outline-none shadow-sm sm:text-sm"
                        />
                        <input
                            type="text"
                            placeholder="Floor / tower (optional)"
                            className="mt-2 p-2 w-full rounded-md border border-gray-300 outline-none shadow-sm sm:text-sm"
                        />
                    </div>
                    <div className="grid grid-cols-2 gap-4 mb-4">
                        <input
                            type="text"
                            placeholder="Area / Sector / Locality*"
                            className="mt-2 p-2 w-full rounded-md border border-gray-300 outline-none shadow-sm sm:text-sm"
                        />
                        <div className="relative">
                            <input
                                type="text"
                                value="Delhi NCR"
                                readOnly
                                className="mt-2 p-2 w-full rounded-md border border-gray-300 outline-none shadow-sm sm:text-sm"
                                placeholder=" "
                            />
                        </div>
                    </div>
                    <input
                        type="text"
                        placeholder="Add any nearby landmark (optional)"
                        className="mt-2 p-2 w-full rounded-md border border-gray-300 outline-none shadow-sm sm:text-sm"
                    />
                    <p className="address-note text-sm px-1 py-2 rounded-md mt-4 -mx-5">
                        Please ensure that this address is the same as mentioned on your FSSAI license
                    </p>
                </form>
            </div>
        </div>
    )
}

export default RestaurantInformation;
